import React, { useState }  from 'react';
import { useNavigate } from "react-router-dom";
import { TreeView } from "@mui/x-tree-view";
import { TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { Snackbar } from '@mui/material';



const AdminMenu = ({ handleLeadsViewClick, handleBidsViewClick, handleBlogPostsViewClick, handleDMSViewClick, handleTicketsViewClick, handleProfileViewClick, handleAccountsViewClick, handleCommoditiesViewClick, handleBillingViewClick, handleDashboardViewClick, handleSubscriptionsViewClick}) => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const handleLogout = () => {
    navigate('/')
    setUser(null);
    setSnackbar({ open: true, message: "Logged out successfully", severity: "success" });
  };

  return (
    <TreeView
    aria-label="file system navigator"
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
  >
    <TreeItem nodeId="1" label="Your Inbox">
      <TreeItem nodeId="2" onClick={handleDashboardViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="11" label="CRM">
        <TreeItem nodeId="12" onClick={handleTicketsViewClick}  label="Tickets" />
        <TreeItem nodeId="17" onClick={handleLeadsViewClick} label="Leads" />
        <TreeItem nodeId="18" onClick={handleBidsViewClick} label="Bids" />
        <TreeItem nodeId="19" onClick={handleDMSViewClick} label="eDMS" />
      </TreeItem>
    <TreeItem nodeId="3" label="Trading">
    <TreeItem nodeId="25" onClick={handleTicketsViewClick} label="View" />
      <TreeItem nodeId="4" onClick={handleCommoditiesViewClick} label="Commodities" />
    </TreeItem>
    <TreeItem nodeId="31" label="Content Marketing">
      <TreeItem nodeId="32" onClick={handleBlogPostsViewClick} label="Blog Posts" />
    </TreeItem>  
    <TreeItem nodeId="13"  label="Billing">
      <TreeItem nodeId="14" onClick={handleBillingViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="5" label="Subscriptions">
      <TreeItem nodeId="10" onClick={handleSubscriptionsViewClick} label="View" />      
    </TreeItem>
    <TreeItem nodeId="22" label="User Accounts">
      <TreeItem nodeId="23" onClick={handleAccountsViewClick} label="View" />
      <TreeItem nodeId="27" onClick={handleProfileViewClick} label="Your Profile" />
    </TreeItem>
    <TreeItem nodeId="15" label="Exit">
      <TreeItem nodeId="16" onClick={handleLogout} label="Sign Out" />
    </TreeItem>
  </TreeView>
  );
};

export default AdminMenu;
