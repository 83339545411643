import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  IconButton,
  Button,
  Modal,
  MenuItem,
  Grid
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const SuperuserCommodities = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [commodities, setCommodities] = useState([]);
  const [filteredCommodities, setFilteredCommodities] = useState([]);
  const [selectedCommodity, setSelectedCommodity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [commodityData, setCommodityData] = useState({
    name: "",
    description: "",
    price: "",
  });

  useEffect(() => {
    fetchCommodities();
  }, []);

  const fetchCommodities = async () => {
    try {
      const response = await axios.get("/commodities");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setCommodities(response.data);
        setFilteredCommodities(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch commodities", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredCommodities(
      commodities.filter(commodity =>
        commodity.name.toLowerCase().includes(value) ||
        commodity.description.toLowerCase().includes(value) ||
        commodity.price.toString().toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (commodityId) => {
    try {
      await axios.delete(`/commodities/${commodityId}`);
      fetchCommodities(); // Refresh the commodities after deletion
    } catch (error) {
      console.error("Failed to delete commodity", error);
    }
  };

  const handleModalOpen = (commodity = null) => {
    if (commodity) {
      setCommodityData(commodity);
    } else {
      setCommodityData({
        name: "",
        description: "",
        price: "",
      });
    }
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setCommodityData({
      name: "",
      description: "",
      price: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCommodityData({
      ...commodityData,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      if (commodityData.id) {
        await axios.put(`/commodities/${commodityData.id}`, commodityData);
      } else {
        await axios.post("/commodities", commodityData);
      }
      fetchCommodities();
      handleModalClose();
    } catch (error) {
      console.error("Failed to submit commodity data", error);
      setLoading(false);
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Commodity Records</Typography>
        <Button variant="contained" color="primary" onClick={() => handleModalOpen()}>
          Add New
        </Button>
      </Box>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredCommodities.length > 0 ? (
              filteredCommodities.map((commodity) => (
                <TableRow key={commodity.id} onClick={() => setSelectedCommodity(commodity)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{commodity.name}</TableCell>
                  <TableCell>{commodity.description}</TableCell>
                  <TableCell>{commodity.price}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleModalOpen(commodity); }}>
                      <FontAwesomeIcon icon={faEdit} color="blue" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(commodity.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No commodity records to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderCommodityDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Commodity Details</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Name: {selectedCommodity.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Description: {selectedCommodity.description}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Price: {selectedCommodity.price}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Commodities</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedCommodity(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Commodities
        </Link>
        {selectedCommodity && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedCommodity.name}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedCommodity ? (
        renderCommodityDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openModal} onClose={handleModalClose}>
        <Paper sx={{ p: 4, mx: 'auto', mt: '10%', maxWidth: 600 }}>
          <Typography variant="h6" gutterBottom>{commodityData.id ? 'Edit Commodity' : 'Add New Commodity'}</Typography>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleFormSubmit}>
            <TextField
              label="Name"
              name="name"
              value={commodityData.name}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Description"
              name="description"
              value={commodityData.description}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Price"
              name="price"
              value={commodityData.price}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default SuperuserCommodities;
