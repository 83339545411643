import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

const QuickLinks = () => {
  return (
    <Box>
      <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2, color:'#df326f' }}>
        Quick Links
      </Typography>
      <List>
        <ListItem button component={Link} to="/about-us">
          <ListItemText primary="About Us" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/blog">
          <ListItemText primary="Our Blog" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/partners">
          <ListItemText primary="Our Partners" />
        </ListItem>
        <Divider />
        <Divider />
        <ListItem button component={Link} to="/affiliate-marketing">
          <ListItemText primary="Affiliate Marketing" />
        </ListItem>
      </List>
    </Box>
  );
};

export default QuickLinks;
