import React from 'react';
import { ThemeProvider } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import useAnalytics from './useAnalytics';
import theme from './theme';
import routes from './routes'; // Import routes

function App() {
  useAnalytics();

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          {/* Redirect all other paths to Home */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
