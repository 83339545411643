import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import SearchBar from "../component/SearchBar";
import SignInModal from "../component/SignInModal";
import { useUser } from '../context/UserContext';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Paper,
  Modal,
  Link,
  Fab,
  Breadcrumbs,
  Chip
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import Copyright from "../component/Copyright";

const DetailsPage = () => {
  const { pename } = useParams(); 
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser(); 
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || "");
  const [filteredTenders, setFilteredTenders] = useState([]);
  const [signInModalOpen, setSignInModalOpen] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get("https://api.accentrixcloud.com/api/tenders");
        const filteredData = response.data.filter(tender => tender.pe_name === pename);

        setDetails(filteredData);
        setFilteredTenders(filteredData.filter((detail) => detail.title.toLowerCase().includes(search.toLowerCase())));
        setLoading(false);
        document.title = `${pename} Tenders | Accentrix Cloud`;
      } catch (error) {
        setError("Failed to fetch details");
        setLoading(false);
      }
    };

    fetchDetails();
  }, [pename, search]);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    setFilteredTenders(details.filter((detail) =>
      detail.title.toLowerCase().includes(value)
    ));
  };

  const handleBidClick = () => {
    if (user) {
      navigate('/user-profile');
    } else {
      setSignInModalOpen(true);
    }
  };

  return (
    <Box maxWidth="100vw">
      <Paper sx={{ position: 'sticky', top: 0, zIndex: 1000, marginTop: 3, marginBottom: 3 }}>
        <SearchBar value={search} onChange={handleSearchChange} />
        <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: '20px' }}>
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color='#df326f'>{pename}</Typography>
        </Breadcrumbs>
      </Paper>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3, marginBottom: 3 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Container maxWidth="md" sx={{ marginBottom: 4 }}>
          <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
            <Link 
              href={`http://${details[0]?.pe_org_url}`} 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                color: theme => theme.palette.text.primary,
                '&:hover': {
                  color: '#df326f', // Change color on hover
                  textDecoration: 'underline' // Add underline on hover
                }
              }}
            >
              <Typography variant="h4" gutterBottom>
                {details[0]?.pe_name}
              </Typography>
            </Link>
            <Typography variant="caption" display="block">
              Email: {details[0]?.pe_email}
            </Typography>
            <Typography variant="caption" display="block">
              Address: {details[0]?.pe_physical_address}, {details[0]?.pe_postal_address}
            </Typography>
            <Typography variant="caption" display="block">
              Telephone: {details[0]?.pe_telephone}
            </Typography>
          </Box>

          <Grid container spacing={2} justifyContent="center">
            {filteredTenders.map((detail, index) => (
              <Grid item xs={12} key={index}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="body2" color='#df326f'>
                      {detail.title}                      
                    </Typography>
                    {detail.is_agpo === 1 && (
                      <Chip label="AGPO" color="success" sx={{ marginLeft: 2 }} />
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>Ref: {detail.tender_ref}</Typography>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: { xs: 'column', sm: 'row' }, 
                        alignItems: { xs: 'flex-start', sm: 'center' },
                        justifyContent: 'space-between', 
                        padding: '10px', 
                        border: '1px solid #ccc', 
                        borderRadius: '4px', 
                        marginTop: '10px'
                      }}
                    >
                      <Typography variant="body2" sx={{ marginBottom: { xs: '10px', sm: '0' } }}>Category: {detail.procurement_category}</Typography>
                      <Typography variant="body2" sx={{ marginBottom: { xs: '10px', sm: '0' } }}>Due in {Math.ceil((new Date(detail.close_at) - new Date()) / (1000 * 60 * 60 * 24))} days</Typography>
                      <Typography variant="body2">Due Date: {new Date(detail.close_at).toLocaleDateString()}</Typography>
                    </Box>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        marginTop: 2 
                      }}
                    >
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleBidClick}
                      >
                        Bid
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
      <Copyright />
      <Fab
        color="primary"
        aria-label="back to top"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </Fab>

      {/* SignInModal */}
      <SignInModal open={signInModalOpen} onClose={() => setSignInModalOpen(false)} />
    </Box>
  );
};

export default DetailsPage;
