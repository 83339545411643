import React, { useState } from 'react';
import { Box, TextField, Typography, AppBar, Toolbar, Button, Link, useMediaQuery, useTheme, Drawer, IconButton, List, ListItem, ListItemText, Modal } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoImage from '../assets/img/logo.png';
import { useUser } from '../context/UserContext';
import axios from 'axios';
import Navbar from './Navbar';

const SearchBar = ({ value, onChange }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const { user, setUser } = useUser();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://api.accentrixcloud.com/api/auth', { email });
      setUser(response.data.user);
      setLoginModalOpen(false);
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleLogout = () => {
    setUser(null);
  };

  const handleForgotPassword = async () => {
    // Handle forgot password logic here
  };

  const handleRegister = async () => {
    // Handle register logic here
  };

  return (
    <>
      < Navbar />
      <Box sx={{
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',  // Center content vertically in the box
      marginBottom: 3,
    }}>
      <TextField
        style={{ width: '80%' }}
        label="Search Keyword or Industry"
        variant="outlined"
        value={value}
        onChange={onChange}
        margin="normal"
      />
      </Box>
    </>
  );
};

export default SearchBar;
