import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  IconButton,
  Button,
  Modal,
  MenuItem,
  Grid
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const SuperuserSubscription = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    user_email: "",
    type: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get("/subscriptions");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setSubscriptions(response.data);
        setFilteredSubscriptions(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch subscriptions", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSubscriptions(
      subscriptions.filter(subscription =>
        subscription.user_email.toLowerCase().includes(value) ||
        subscription.type.toLowerCase().includes(value) ||
        new Date(subscription.start_date).toLocaleString().toLowerCase().includes(value) ||
        new Date(subscription.end_date).toLocaleString().toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (subscriptionId) => {
    try {
      await axios.delete(`/subscriptions/${subscriptionId}`);
      fetchSubscriptions(); // Refresh the subscriptions after deletion
    } catch (error) {
      console.error("Failed to delete subscription", error);
    }
  };

  const handleModalOpen = (subscription = null) => {
    if (subscription) {
      setSubscriptionData(subscription);
    } else {
      setSubscriptionData({
        user_email: "",
        type: "",
        start_date: "",
        end_date: "",
      });
    }
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSubscriptionData({
      user_email: "",
      type: "",
      start_date: "",
      end_date: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionData({
      ...subscriptionData,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      if (subscriptionData.id) {
        await axios.put(`/subscriptions/${subscriptionData.id}`, subscriptionData);
      } else {
        await axios.post("/subscriptions", subscriptionData);
      }
      fetchSubscriptions();
      handleModalClose();
    } catch (error) {
      console.error("Failed to submit subscription data", error);
      setLoading(false);
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Subscription Records</Typography>
        <Button variant="contained" color="primary" onClick={() => handleModalOpen()}>
          Add New
        </Button>
      </Box>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>User Email</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredSubscriptions.length > 0 ? (
              filteredSubscriptions.map((subscription) => (
                <TableRow key={subscription.id} onClick={() => setSelectedSubscription(subscription)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{subscription.user_email}</TableCell>
                  <TableCell>{subscription.type}</TableCell>
                  <TableCell>{new Date(subscription.start_date).toLocaleString()}</TableCell>
                  <TableCell>{new Date(subscription.end_date).toLocaleString()}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleModalOpen(subscription); }}>
                      <FontAwesomeIcon icon={faEdit} color="blue" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(subscription.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No subscription records to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderSubscriptionDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Subscription Details</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>User Email: {selectedSubscription.user_email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Type: {selectedSubscription.type}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Start Date: {new Date(selectedSubscription.start_date).toLocaleString()}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>End Date: {new Date(selectedSubscription.end_date).toLocaleString()}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Subscriptions</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedSubscription(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Subscriptions
        </Link>
        {selectedSubscription && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedSubscription.user_email}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedSubscription ? (
        renderSubscriptionDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openModal} onClose={handleModalClose}>
        <Paper sx={{ p: 4, mx: 'auto', mt: '10%', maxWidth: 600 }}>
          <Typography variant="h6" gutterBottom>{subscriptionData.id ? 'Edit Subscription' : 'Add New Subscription'}</Typography>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleFormSubmit}>
            <TextField
              label="User Email"
              name="user_email"
              value={subscriptionData.user_email}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Type"
              name="type"
              value={subscriptionData.type}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Start Date"
              name="start_date"
              type="date"
              value={subscriptionData.start_date}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <TextField
              label="End Date"
              name="end_date"
              type="date"
              value={subscriptionData.end_date}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default SuperuserSubscription;