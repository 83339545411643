import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Button, List, ListItem, ListItemText, Divider, AppBar, Toolbar, Grid, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Modal, Link as MuiLink, Breadcrumbs, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import Navbar from '../component/Navbar';
import Copyright from "../component/Copyright";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS
import QuickLinks from '../component/QuickLinks';

import bgImg1 from '../assets/img/yard1.png'
import bgImg2 from '../assets/img/yard2.png'
import bgImg3 from '../assets/img/yard3.png'

const CommoditiesTrading = () => {
    const { started, setStarted, appState, setAppState } = useUser();
    const [commodities, setCommodities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch commodities data from the API
        fetch('https://theyard.accentrixcloud.com/api/commodities')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCommodities(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    const handleGetStarted = () => {
        setStarted(true);
        setAppState({ ...appState, started: true });
    };

    const handleCloseModal = () => {
        setError(null);
    };

    return (
        <Box>
            <Helmet>
            <title>The Yard - Kenya's Commodities Exchange Hub | Accentrix Cloud</title>
            <meta name="description" content="The Yard is your premier commodities exchange platform in Kenya. Trade, manage, and optimize your commodities with ease and efficiency." />
        </Helmet>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>   
            <Box>

                {/* Carousel Section */}
                <Box sx={{ marginBottom: 3 }}>
                    <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={true} dynamicHeight={true}>
                        <div>
                            <img src={bgImg1} alt="Feature 1" style={{ height: '50vh', objectFit: 'cover' }} />
                        </div>
                        <div>
                            <img src={bgImg2} alt="Feature 2" style={{ height: '50vh', objectFit: 'cover' }} />
                        
                        </div>
                        <div>
                            <img src={bgImg3} alt="Feature 3" style={{ height: '50vh', objectFit: 'cover' }} />
                            
                        </div>
                    </Carousel>
                </Box>
                <Container>
                    {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <Typography color="textPrimary">Commodities Trading App</Typography>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={8} lg={8} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color: '#df326f' }}>
                                    Live Commodities Prices
                                </Typography>
                                <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderRadius: 1, marginBottom: 3 }}>
                                    {loading ? (
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                            <Typography variant="body1" sx={{ marginBottom: 1 }}>
                                                Commodities prices are updated in real-time to reflect the current market values.
                                            </Typography>
                                            {commodities.map((commodity) => (
                                                <Typography key={commodity.id} variant="body2">{commodity.name}: {commodity.price} {commodity.uom}</Typography>
                                            ))}
                                        </>
                                    )}
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        component={Link} 
                                        to="https://trading.accentrixcloud.com/"
                                        sx={{ backgroundColor: '#df326f', '&:hover': { backgroundColor: '#b02455' }, marginBottom: 3, mt: 3 }}
                                        onClick={handleGetStarted}
                                    >
                                        Get Started
                                    </Button>
                                </Box>

                                {/* How It Works Section */}
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color: '#df326f' }}>
                                    How It Works
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                                    Our platform connects buyers and sellers of commodities, minerals, and local produce. Sellers can list their products with detailed descriptions and prices, while buyers can browse through the listings and make purchases directly on the platform.
                                </Typography>
                                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1, color: '#df326f' }}>Step-by-Step Guide:</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>1. Sign Up and create your profile.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>2. List your commodities with detailed descriptions and prices.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>3. Browse through listings to find items of interest.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>4. Contact sellers directly through our messaging system.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>5. Complete transactions securely on our platform.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                {/* Quick Links Section */}
                                <QuickLinks />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                </Container>
                <Container>                       
                {/* FAQs Section */}
                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color: '#df326f' }}>
                    Frequently Asked Questions (FAQs)
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>What is The Yard?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            The Yard is a premier platform for trading commodities, minerals, and any other local produce. It connects buyers and sellers seamlessly.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How do I sign up?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Click on the "Get Started" button and follow the instructions to create your profile.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How are transactions secured?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            All transactions on The Yard are secured using the latest encryption technologies to ensure your data and transactions are safe.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{mb: 2}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Can I list multiple items?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes, you can list multiple items. Simply go to your profile and add new listings with detailed descriptions and prices.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                </Container>                     
            </Box>

            {/* Error Modal */}
            <Modal
                open={!!error}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                    <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
            
            <Copyright />
        </Box>
    );
};

export default CommoditiesTrading;
