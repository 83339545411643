import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Link,
  useMediaQuery,
  useTheme,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoImage from '../assets/img/logo.png';
import { useUser } from '../context/UserContext';
import SignInModal from './SignInModal'; // Adjust the import path as needed

const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const { user, setUser } = useUser();
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = () => {
    navigate('/');
  };

  const handleLogout = () => {
    setUser(null);
    setSnackbar({ open: true, message: "Logged out successfully", severity: "success" });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <Box
        component="img"
        src={LogoImage}
        alt="Company Logo"
        sx={{ width: 100, height: 'auto', display: 'block', margin: '0 auto' }}
        onClick={handleClick}
      />
      <Typography variant='body1' align="center" sx={{ marginBottom: 1, color: '#df326f' }}>
        accentrixcloud.com
      </Typography>

      {isMediumScreen ? (
        <>
          <AppBar position="static" sx={{ backgroundColor: '#df326f' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                <Box sx={{ width: 250 }}>
                  <IconButton onClick={handleDrawerToggle}>
                    <CloseIcon />
                  </IconButton>
                  <List>
                    <ListItem button component={Link} href="/">
                      <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button component={Link} href="/commodities-trading">
                      <ListItemText primary="Commodities Trading" />
                    </ListItem>
                    <ListItem button component={Link} href="/asset-management">
                      <ListItemText primary="Asset Management" />
                    </ListItem>
                    <ListItem button component={Link} href="/retail-app">
                      <ListItemText primary="Retail App" />
                    </ListItem>
                    <ListItem button component={Link} href="/restaurant-app">
                      <ListItemText primary="Restaurant App" />
                    </ListItem>
                    <ListItem button component={Link} href="/micro-finance-app">
                      <ListItemText primary="Micro Finance App" />
                    </ListItem>
                    <ListItem button component={Link} href="/contact-us">
                      <ListItemText primary="Contacts" />
                    </ListItem>
                    {user ? (
                      <ListItem button onClick={handleLogout}>
                        <ListItemText primary="Log Out" />
                      </ListItem>
                    ) : (
                      <ListItem>
                        <Button
                          sx={{ backgroundColor: 'primary.main', color: 'white' }}
                          onClick={() => setLoginModalOpen(true)}
                        >
                          Log In
                        </Button>
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Drawer>
            </Toolbar>
          </AppBar>
        </>
      ) : (
        <AppBar position="static" sx={{ backgroundColor: '#df326f', display: isSmallScreen ? 'none' : 'block' }}>
          <Toolbar sx={{ justifyContent: 'center' }}>
            <Button color="inherit" component={Link} href="/">Home</Button>
            <Button color="inherit" component={Link} href="/commodities-trading">Commodities Trading</Button>
            <Button color="inherit" component={Link} href="/asset-management">Asset Management</Button>
            <Button color="inherit" component={Link} href="/retail-app">Retail App</Button>
            <Button color="inherit" component={Link} href="/restaurant-app">Restaurant App</Button>
            <Button color="inherit" component={Link} href="/micro-finance-app">Micro Finance App</Button>
            <Button color="inherit" component={Link} href="/contact-us">Contacts</Button>
            {user ? (
              <Button
                sx={{
                  backgroundColor: 'orange',
                  '&:hover': {
                    backgroundColor: 'coral',
                  },
                  color: 'white',
                  marginLeft: 2
                }}
                onClick={handleLogout}
              >
                Log Out
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: 'orange',
                  '&:hover': {
                    backgroundColor: 'coral',
                  },
                  color: 'white',
                  marginLeft: 2
                }}
                onClick={() => setLoginModalOpen(true)}
              >
                Log In
              </Button>
            )}
          </Toolbar>
        </AppBar>
      )}

      <SignInModal
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Navbar;
