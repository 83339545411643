import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, CssBaseline, Paper, Avatar, useTheme, CircularProgress, Snackbar, Alert } from '@mui/material';
import logo from "../assets/img/favicon.png";

function SetPassword() {
    const theme = useTheme();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const email = query.get('email');

    useEffect(() => {
        if (password || confirmPassword) {
            setError('');
        }
    }, [password, confirmPassword]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setSnackbar({ open: true, message: "Passwords do not match", severity: "error" });
            return;
        }

        setLoading(true);
        try {
            const response = await axios.put('https://api.accentrixcloud.com/api/set-password', {
                email,
                password
            });
            if (response.status === 200) {
                setSuccess(true);
                setSnackbar({ open: true, message: "Password set successfully! Redirecting...", severity: "success" });
                setTimeout(() => navigate('/'), 3000);
            }
        } catch (err) {
            setSnackbar({ open: true, message: "Failed to set password", severity: "error" });
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 8, mb: 4 }}>
            <CssBaseline />
            <Paper
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 3,
                    bgcolor: "#e0e0e0",
                    borderRadius: 3,
                    color: "#ffffff",
                }}
            >
                <Avatar
                    src={logo}
                    sx={{ mt: 2, bgcolor: "common.white", width: 86, height: 86 }}
                />
                <Typography
                    component="h1"
                    variant="h5"
                    sx={{ mb: 2, color: "#df326f" }}
                >
                    Set Your Password
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                {success ? (
                    <Typography>
                        Password set successfully! Redirecting to accentrixcloud.com...
                    </Typography>
                ) : (
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="new-password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            sx={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                bgcolor: "primary.main",
                                "&:hover": { bgcolor: "primary.dark" },
                            }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : "Set Password"}
                        </Button>
                    </Box>
                )}
            </Paper>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default SetPassword;
