import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button, Grid, Container, Breadcrumbs, Link as MuiLink, Fab } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faWhatsapp, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../component/Navbar';
import QuickLinks from '../component/QuickLinks';
import Copyright from '../component/Copyright';

const Article = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await fetch(`https://api.accentrixcloud.com/api/insight/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch the article');
                }
                const data = await response.json();
                setPost(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPost();
    }, [id]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" color="error">{error}</Typography>
            </Box>
        );
    }

    const formatContent = (content) => {
        return content.split('\n\n').map((paragraph, index) => (
            <Typography key={index} variant="body1" sx={{ marginBottom: 2, textAlign: 'justify' }}>
                {paragraph}
            </Typography>
        ));
    };

    return (
        <Box>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>
            <Container>
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2, mt: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <MuiLink component={Link} to="/blog" color="inherit">
                        Our Blog
                    </MuiLink>
                    <Typography color="textPrimary">{post[0].title}</Typography>
                </Breadcrumbs>
                <Grid container spacing={2} sx={{ paddingTop: 3 }}>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ padding: 3 }}>
                            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                                {post[0].title}
                            </Typography>
                            {formatContent(post[0].content)}
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                <Button
                                    component="a"
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: "#3b5998" }}
                                >
                                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                                </Button>
                                <Button
                                    component="a"
                                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: "#0e76a8" }}
                                >
                                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                </Button>
                                <Button
                                    component="a"
                                    href={`https://api.whatsapp.com/send?text=${window.location.href}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: "#25D366" }}
                                >
                                    <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                                </Button>
                                <Button
                                    component="a"
                                    href={`https://twitter.com/share?url=${window.location.href}&text=Check%20this%20out!`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: "#1DA1F2" }}
                                >
                                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                                </Button>
                            </Box>
                            <Fab
                                onClick={scrollToTop}
                                sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: '#df326f', color: '#fff', '&:hover': { backgroundColor: '#b02455' } }}
                            >
                                <FontAwesomeIcon icon={faArrowUp} size="2x" />
                            </Fab>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <QuickLinks />
                    </Grid>
                </Grid>
            </Container>
            <Copyright />
        </Box>
    );
};

export default Article;
