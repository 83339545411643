import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  IconButton,
  Button,
  Modal,
  MenuItem,
  Grid
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const SuperuserBids = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [bids, setBids] = useState([]);
  const [filteredBids, setFilteredBids] = useState([]);
  const [selectedBid, setSelectedBid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [bidData, setBidData] = useState({
    title: "",
    amount: "",
    status: "",
  });

  useEffect(() => {
    fetchBids();
  }, []);

  const fetchBids = async () => {
    try {
      const response = await axios.get("/bids");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setBids(response.data);
        setFilteredBids(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch bids", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBids(
      bids.filter(bid =>
        bid.title.toLowerCase().includes(value) ||
        bid.amount.toString().toLowerCase().includes(value) ||
        bid.status.toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (bidId) => {
    try {
      await axios.delete(`/bids/${bidId}`);
      fetchBids(); // Refresh the bids after deletion
    } catch (error) {
      console.error("Failed to delete bid", error);
    }
  };

  const handleModalOpen = (bid = null) => {
    if (bid) {
      setBidData(bid);
    } else {
      setBidData({
        title: "",
        amount: "",
        status: "",
      });
    }
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setBidData({
      title: "",
      amount: "",
      status: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBidData({
      ...bidData,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      if (bidData.id) {
        await axios.put(`/bids/${bidData.id}`, bidData);
      } else {
        await axios.post("/bids", bidData);
      }
      fetchBids();
      handleModalClose();
    } catch (error) {
      console.error("Failed to submit bid data", error);
      setLoading(false);
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Bid Records</Typography>
        <Button variant="contained" color="primary" onClick={() => handleModalOpen()}>
          Add New
        </Button>
      </Box>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Title</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredBids.length > 0 ? (
              filteredBids.map((bid) => (
                <TableRow key={bid.id} onClick={() => setSelectedBid(bid)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{bid.title}</TableCell>
                  <TableCell>{bid.amount}</TableCell>
                  <TableCell>{bid.status}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleModalOpen(bid); }}>
                      <FontAwesomeIcon icon={faEdit} color="blue" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(bid.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No bid records to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderBidDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Bid Details</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Title: {selectedBid.title}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Amount: {selectedBid.amount}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Status: {selectedBid.status}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Bids</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedBid(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Bids
        </Link>
        {selectedBid && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedBid.title}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedBid ? (
        renderBidDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openModal} onClose={handleModalClose}>
        <Paper sx={{ p: 4, mx: 'auto', mt: '10%', maxWidth: 600 }}>
          <Typography variant="h6" gutterBottom>{bidData.id ? 'Edit Bid' : 'Add New Bid'}</Typography>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleFormSubmit}>
            <TextField
              label="Title"
              name="title"
              value={bidData.title}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Amount"
              name="amount"
              value={bidData.amount}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              select
              label="Status"
              name="status"
              value={bidData.status}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Accepted">Accepted</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </TextField>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default SuperuserBids;