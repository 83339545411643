import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Container,
  Divider,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Badge,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import LogoutIcon from '@mui/icons-material/Logout';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Navbar from "../component/Navbar";
import UserSubscription from "../component/UserSubscription";
import UserMenu from "../component/UserMenu";
import Copyright from "../component/Copyright";

const drawerWidth = 240;

const actions = [
    { icon: <LogoutIcon />, name: "Log Off" },
    { icon: <ShareIcon />, name: "Share" },
    { icon: <ContactSupportIcon />, name: "Talk to Us" },
    { icon: <DashboardIcon />, name: "Dashboard" },
  ];

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
  },
}));

const ViewUserSubscription = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleBillingViewClick = () => {
    navigate('/user-billing');
  };

  const handleDashboardViewClick = () => {
    navigate('/user-profile');
  };

  const handleBidsViewClick = () => {
    navigate('/user-bids');
  };

  const handleLeadsViewClick = () => {
    navigate('/user-leads');
  };

  const handleDMSViewClick = () => {
    navigate('/user-dms');
  };

  const handleSubscriptionsViewClick = () => {
    navigate('/user-subscription');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{ marginRight: '36px' }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            accentrixcloud.com
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <FontAwesomeIcon icon={faBell} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          <ListItem button onClick={handleDashboardViewClick}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <UserMenu
            handleBillingViewClick={handleBillingViewClick}
            handleBidsViewClick={handleBidsViewClick}
            handleDashboardViewClick={handleDashboardViewClick}
            handleLeadsViewClick={handleLeadsViewClick}
            handleDMSViewClick={handleDMSViewClick}
            handleSubscriptionsViewClick={handleSubscriptionsViewClick}
          />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <UserSubscription />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="caption" style={{ color: "text.primary" }}>
                All Rights Reserved
              </Typography>
              <Typography style={{ color: "#df326f" }}>
                www.accentrixcloud.com
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Copyright />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
                <SpeedDial
                  ariaLabel="SpeedDial"
                  sx={{
                    "& .MuiFab-primary": {
                      backgroundColor: "#df326f",
                      "&:hover": { backgroundColor: "coral" },
                    },
                  }}
                  icon={<SpeedDialIcon />}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                    />
                  ))}
                </SpeedDial>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default ViewUserSubscription;
