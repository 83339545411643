import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import axios from "axios";

const SuperuserUserAccounts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.accentrixcloud.com/api/accounts");
      setAccounts(response.data);
      setFilteredAccounts(response.data);
    } catch (error) {
      setError("Failed to fetch accounts data");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAccounts(
      accounts.filter(
        (account) =>
          account.name.toLowerCase().includes(value) ||
          account.email.toLowerCase().includes(value) ||
          account.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleEditClick = (account) => {
    setSelectedAccount(account);
    setFormData(account);
    setOpenEditModal(true);
  };

  const handleDeleteClick = (account) => {
    setSelectedAccount(account);
    setOpenDeleteModal(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) && email.endsWith('@accentrixcloud.com');
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^254\d{9}$/;
    return phoneRegex.test(phone);
  };

  const handlePhoneChange = (e) => {
    let { value } = e.target;
    if (/^\d*$/.test(value)) {
      if (!value.startsWith("254")) {
        value = "254" + value.replace(/^0+/, "");
      }
      if (value.startsWith("2540")) {
        value = "254" + value.slice(4);
      }
      setFormData({ ...formData, phone: value });
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(formData.email)) {
      setSnackbar({ open: true, message: "Invalid email format. Must end with @accentrixcloud.com.", severity: "error" });
      return;
    }
    if (!validatePhone(formData.phone)) {
      setSnackbar({ open: true, message: "Invalid phone format. Must start with 254 and be 12 digits long.", severity: "error" });
      return;
    }
    setLoadingEdit(true);
    try {
      const response = await axios.put(`https://api.accentrixcloud.com/api/account/${selectedAccount.account_id}`, formData);
      setSnackbar({ open: true, message: response.data.message, severity: "success" });
      fetchAccounts();
      setOpenEditModal(false);
    } catch (error) {
      setSnackbar({ open: true, message: error.response.data.message || "Failed to update account data", severity: "error" });
    } finally {
      setLoadingEdit(false);
    }
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      const response = await axios.delete(`https://api.accentrixcloud.com/api/account/${selectedAccount.account_id}`);
      setSnackbar({ open: true, message: response.data.message, severity: "success" });
      fetchAccounts();
      setOpenDeleteModal(false);
    } catch (error) {
      setSnackbar({ open: true, message: error.response.data.message || "Failed to delete account data", severity: "error" });
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(formData.email)) {
      setSnackbar({ open: true, message: "Invalid email format. Must end with @accentrixcloud.com.", severity: "error" });
      return;
    }
    if (!validatePhone(formData.phone)) {
      setSnackbar({ open: true, message: "Invalid phone format. Must start with 254 and be 12 digits long.", severity: "error" });
      return;
    }
    setLoadingAdd(true);
    try {
      const response = await axios.post("https://api.accentrixcloud.com/api/accounts", formData);
      setSnackbar({ open: true, message: response.data.message, severity: "success" });
      fetchAccounts();
      setOpenAddModal(false);
    } catch (error) {
      setSnackbar({ open: true, message: error.response.data.message || "Failed to add account", severity: "error" });
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date Created</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAccounts.map((account) => (
            <TableRow key={account.account_id}>
              <TableCell>{new Date(account.created_at).toLocaleString()}</TableCell>
              <TableCell>{account.name}</TableCell>
              <TableCell>{account.email}</TableCell>
              <TableCell>{account.phone}</TableCell>
              <TableCell>{account.is_active === 0 ? 'Inactive' : 'Active'}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEditClick(account)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(account)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>User Accounts</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search accounts"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddModal(true)}
          sx={{ ml: 2 }}
        >
          Add User
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredAccounts.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No user accounts found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Edit Account</Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name || ""}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              value={formData.phone || ""}
              onChange={handlePhoneChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loadingEdit}
            >
              {loadingEdit ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Create Account</Typography>
          <Box component="form" onSubmit={handleAddSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name || ""}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              value={formData.phone || ""}
              onChange={handlePhoneChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loadingAdd}
            >
              {loadingAdd ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this user account?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={loadingDelete}
          >
            {loadingDelete ? <CircularProgress size={24} /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserUserAccounts;
