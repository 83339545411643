import React, { useEffect, useState } from 'react';
import { Button, Box, Grid, Typography, Card, CardContent, CardActionArea, CircularProgress, Link as MuiLink, Container, Breadcrumbs, Hidden } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faWhatsapp, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from '../component/Navbar';
import QuickLinks from '../component/QuickLinks';
import Carousel from 'react-responsive-carousel/lib/js/components/Carousel/index';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel CSS

import assetBgImg1 from '../assets/img/blog1.png';
import assetBgImg2 from '../assets/img/blog2.png';
import assetBgImg3 from '../assets/img/blog3.png';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch('https://api.accentrixcloud.com/api/insights');
                if (!response.ok) {
                    throw new Error('Failed to fetch posts');
                }
                const data = await response.json();
                setPosts(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const handlePostClick = (id) => {
        navigate(`/article/${id}`);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Helmet>
                <title>Blog | Accentrix Cloud</title>
                <meta name="description" content="Read our latest articles on cloud solutions, technology trends, and insights from Accentrix Cloud. Stay updated with our blog." />
            </Helmet>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box> 
            <Box>
                <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={true} dynamicHeight={true}>
                    <div>
                        <img src={assetBgImg1} alt="Feature 1" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                    </div>
                    <div>
                        <img src={assetBgImg2} alt="Feature 2" style={{ height: '50vh', objectFit: 'cover' }} />
                    </div>
                    <div>
                        <img src={assetBgImg3} alt="Feature 3" style={{ height: '50vh', objectFit: 'cover' }} />
                    </div>
                </Carousel>
            </Box>
            <Container>
                {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2, mt: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <Typography color="textPrimary">Our Blog</Typography>
                </Breadcrumbs>   
                <Grid container spacing={2} sx={{ marginTop: 4 }}>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Insights and Latest News
                        </Typography>
                        {posts.map((post) => (
                            <Card key={post.id} sx={{ marginBottom: 2 }}>
                                <CardActionArea onClick={() => handlePostClick(post.id)}>
                                    <CardContent>
                                        <Typography variant="body2" color='#df326f'>{post.title}</Typography>
                                        <Typography variant="caption">{post.excerpt}</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <QuickLinks />
                    </Grid>
                </Grid>
            </Container>
            <Container>
                {/* Share Your Experience Section */}
                <Box sx={{ marginTop: 4, marginBottom: 4, textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                        Share Your Experience
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Button
                            component="a"
                            href="https://www.facebook.com/sharer/sharer.php?u=accentrixcloud.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#3b5998" }}
                        >
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                        </Button>
                        <Button
                            component="a"
                            href="https://www.linkedin.com/shareArticle?mini=true&url=accentrixcloud.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#0e76a8" }}
                        >
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </Button>
                        <Hidden smUp>
                            <Button
                                component="a"
                                href="https://api.whatsapp.com/send?text=accentrixcloud.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: "#25D366" }}
                            >
                                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                            </Button>
                        </Hidden>
                        <Button
                            component="a"
                            href="https://twitter.com/share?url=accentrixcloud.com&text=Check%20this%20out!"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#1DA1F2" }}
                        >
                            <FontAwesomeIcon icon={faTwitter} size="2x" />
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Blog;
