import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { TreeView } from "@mui/x-tree-view";
import { TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useUser } from '../context/UserContext';




const UserMenu = ({ handleQuestionaireViewClick, handleBillingViewClick, handleDashboardViewClick, handleAppointmentsViewClick, handleLeadsViewClick, handleBidsViewClick, handleDMSViewClick, handlePostRecoveryViewClick, handleSubscriptionsViewClick}) => {
  
  const { user, setUser } = useUser();

  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const handleLogout = () => {
    navigate('/')
    setUser(null);
    setSnackbar({ open: true, message: "Logged out successfully", severity: "success" });
  };
  
  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="1" label="Your Inbox">
        <TreeItem nodeId="2" onClick={handleDashboardViewClick} label="View" />
      </TreeItem>
      <TreeItem nodeId="3" label="CRM">
        <TreeItem nodeId="4" onClick={handleLeadsViewClick} label="Leads" />
        <TreeItem nodeId="19" onClick={handleBidsViewClick} label="Bids" />
        <TreeItem nodeId="20" onClick={handleDMSViewClick} label="eDMS" />
      </TreeItem>
      <TreeItem nodeId="11" label="Billing">
        <TreeItem nodeId="12" onClick={handleBillingViewClick} label="View" />
      </TreeItem>
      <TreeItem nodeId="22" label="Subscriptions">
        <TreeItem nodeId="23" onClick={handleSubscriptionsViewClick} label="View" />
      </TreeItem>
      <TreeItem nodeId="6" label="Exit">
        <TreeItem nodeId="16" onClick={handleLogout} label="Sign Out"/>
      </TreeItem>
    </TreeView>
  );
};

export default UserMenu;
