import React, { useState, useEffect } from "react";
import {
  Container,
  useTheme,
  Modal,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Fab
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoCard from "../component/InfoCard";
import SearchBar from "../component/SearchBar";
import axios from "axios";
import Copyright from "../component/Copyright";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const LandingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      // Fetch data from the provided API endpoint
      const response = await axios.get("https://api.accentrixcloud.com/api/tenders");
      const data = response.data; // Adjust based on your API structure
      
      // Grouping tenders by 'pe_name'
      const groupedTenders = data.reduce((acc, curr) => {
        const key = curr.pe_name;  // Group by organization name (pe_name)
        if (!acc[key]) acc[key] = [];
        acc[key].push(curr);
        return acc;
      }, {});

      // Formatting grouped tenders for display
      const tendersFormatted = Object.keys(groupedTenders).map((key) => ({
        pename: key,
        details: groupedTenders[key],
      }));

      setCards(tendersFormatted);
      setFilteredCards(tendersFormatted);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch data");
      setOpen(true);
      setLoading(false);
    }
  };

  const handleCardClick = (pename) => {
    navigate(`/${pename}?search=${search}`); // Navigate to the new route with search term
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);

    const filtered = cards.flatMap((card) => {
      const matchedTenders = card.details.filter((detail) =>
        detail.title.toLowerCase().includes(value) || card.pename.toLowerCase().includes(value)
      );
      return matchedTenders.length > 0 ? { pename: card.pename, details: matchedTenders } : [];
    });

    setFilteredCards(filtered);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box maxWidth="100vw">
      <Box sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}>
          <SearchBar value={search} onChange={handleSearchChange} />
        </Box>
        <Container sx={{
      display: 'flex',
      flexDirection: 'column', // Use column layout to manage vertical spacing
      alignItems: 'center', // Center align items
      padding: { xs: '0 10px', sm: '0 15px', md: '0 20px', lg: '0 20px' },
      maxWidth: { xs: '100%', sm: '100%', md: '960px', lg: '1280px' }
    }}>
      {loading ? (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
          marginBottom: 3,
        }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} justifyContent="center"> {/* Using Grid container */}
          {filteredCards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}> {/* Responsive Grid items */}
              <InfoCard
                title={card.pename} // Organization name
                description={`Tenders: ${card.details.length}`} // Number of tenders
                onClick={() => handleCardClick(card.pename)}
              >
                <div>
                  {card.details.map((detail) => (
                    <div key={detail.id}>
                      <h2>{detail.title}</h2>
                      <p><strong>Tender Ref:</strong> {detail.tender_ref}</p>
                      <p><strong>Closing Date:</strong> {new Date(detail.close_at).toLocaleDateString()}</p>
                      <p><strong>Organization:</strong> {detail.pe_name}</p>
                      <p><strong>Website:</strong> <a href={`http://${detail.pe_org_url}`} target="_blank" rel="noopener noreferrer">{detail.pe_org_url}</a></p>
                      <p><strong>Physical Address:</strong> {detail.pe_physical_address}</p>
                      <p><strong>Postal Address:</strong> {detail.pe_postal_address}</p>
                      <p><strong>Telephone:</strong> {detail.pe_telephone}</p>
                      <p><strong>Email:</strong> <a href={`mailto:${detail.pe_email}`}>{detail.pe_email}</a></p>
                    </div>
                  ))}
                </div>
              </InfoCard>
            </Grid>
          ))}
        </Grid>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 250, bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
          <Typography id="modal-title" variant="h6">Error</Typography>
          <Typography id="modal-description">{error}</Typography>
        </Box>
      </Modal>
      <Fab
        color="primary"
        aria-label="scroll to top"
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: '#df326f', '&:hover': { backgroundColor: '#b02455' } }}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </Fab>
    </Container>
      <Copyright />
    </Box>
  );
};

export default LandingPage;
