import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  IconButton,
  Button,
  Modal,
  MenuItem,
  Grid
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const SuperuserBilling = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [billings, setBillings] = useState([]);
  const [filteredBillings, setFilteredBillings] = useState([]);
  const [selectedBilling, setSelectedBilling] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [billingData, setBillingData] = useState({
    user_email: "",
    amount: "",
    status: "",
  });

  useEffect(() => {
    fetchBillings();
  }, []);

  const fetchBillings = async () => {
    try {
      const response = await axios.get("/billing");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setBillings(response.data);
        setFilteredBillings(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch billings", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBillings(
      billings.filter(billing =>
        billing.user_email.toLowerCase().includes(value) ||
        billing.amount.toString().toLowerCase().includes(value) ||
        billing.status.toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (billingId) => {
    try {
      await axios.delete(`/billing/${billingId}`);
      fetchBillings(); // Refresh the billings after deletion
    } catch (error) {
      console.error("Failed to delete billing", error);
    }
  };

  const handleModalOpen = (billing = null) => {
    if (billing) {
      setBillingData(billing);
    } else {
      setBillingData({
        user_email: "",
        amount: "",
        status: "",
      });
    }
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setBillingData({
      user_email: "",
      amount: "",
      status: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingData({
      ...billingData,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      if (billingData.id) {
        await axios.put(`/billing/${billingData.id}`, billingData);
      } else {
        await axios.post("/billing", billingData);
      }
      fetchBillings();
      handleModalClose();
    } catch (error) {
      console.error("Failed to submit billing data", error);
      setLoading(false);
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Billing Records</Typography>
        <Button variant="contained" color="primary" onClick={() => handleModalOpen()}>
          Add New
        </Button>
      </Box>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>User Email</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredBillings.length > 0 ? (
              filteredBillings.map((billing) => (
                <TableRow key={billing.id} onClick={() => setSelectedBilling(billing)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{billing.user_email}</TableCell>
                  <TableCell>{billing.amount}</TableCell>
                  <TableCell>{billing.status}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleModalOpen(billing); }}>
                      <FontAwesomeIcon icon={faEdit} color="blue" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(billing.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No billing records to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderBillingDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Billing Details</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>User Email: {selectedBilling.user_email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Amount: {selectedBilling.amount}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Status: {selectedBilling.status}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Billing</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedBilling(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Billing Records
        </Link>
        {selectedBilling && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedBilling.user_email}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedBilling ? (
        renderBillingDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openModal} onClose={handleModalClose}>
        <Paper sx={{ p: 4, mx: 'auto', mt: '10%', maxWidth: 600 }}>
          <Typography variant="h6" gutterBottom>{billingData.id ? 'Edit Billing' : 'Add New Billing'}</Typography>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleFormSubmit}>
            <TextField
              label="User Email"
              name="user_email"
              value={billingData.user_email}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Amount"
              name="amount"
              value={billingData.amount}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              select
              label="Status"
              name="status"
              value={billingData.status}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
              <MenuItem value="Overdue">Overdue</MenuItem>
            </TextField>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default SuperuserBilling;