import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Button, Grid, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Modal, Link as MuiLink, Container, Breadcrumbs } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import Navbar from '../component/Navbar';
import QuickLinks from '../component/QuickLinks';
import Copyright from "../component/Copyright";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS
import assetBgImg1 from '../assets/img/asset Manager pro.png'
import assetBgImg2 from '../assets/img/banner2.png'
import assetBgImg3 from '../assets/img/banner3.png'
const AssetManagement = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Simulate API call for demo purposes
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleCloseModal = () => {
        setError(null);
    };

    return (
        <Box>
            <Helmet>
            <title>Asset Management -  Manage Assets Efficiently | Accentrix Cloud</title>
            <meta name="description" content="Our Asset Management app provides a comprehensive platform for managing assets efficiently. Track, manage, and optimize your assets seamlessly." />
        </Helmet>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>   
            <Box>

                {/* Carousel Section */}
                <Box sx={{ marginBottom: 3 }}>
                    <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={true} dynamicHeight={true}>
                        <div>
                            <img src={assetBgImg1} alt="Feature 1" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                                                    </div>
                        <div>
                            <img src={assetBgImg2} alt="Feature 2" style={{ height: '50vh', objectFit: 'cover' }} />
                            
                        </div>
                        <div>
                            <img src={assetBgImg3} alt="Feature 3" stle={{ height: '50vh', objectFit: 'cover' }} />
                            
                        </div>
                    </Carousel>
                </Box>
                <Container>
                {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <Typography color="textPrimary">Asset Management App</Typography>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={8} lg={8} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2,  color: '#df326f' }}>
                                    Features of Asset Manager Pro
                                </Typography>
                                <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderRadius: 1, marginBottom: 3 }}>
                                            <Typography variant="body1" sx={{ marginBottom: 1 }}>
                                                Asset Manager Pro is designed to provide you with the best tools for managing your assets efficiently and effectively.
                                            </Typography>
                                            <Typography variant="body2">• Real-time analytics and insights</Typography>
                                            <Typography variant="body2">• Comprehensive asset tracking</Typography>
                                            <Typography variant="body2">• Secure and reliable platform</Typography>
                                            <Typography variant="body2">• Easy-to-use interface</Typography>
                                            <Typography variant="body2">• Customized reporting</Typography>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        component="a"
                                        href="https://asset-management.accentrixcloud.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ backgroundColor: '#df326f', '&:hover': { backgroundColor: '#b02455' }, marginBottom: 3, mt: 3 }}
                                    >
                                        View Demo
                                    </Button>
                                </Box>

                                {/* How It Works Section */}
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2,  color: '#df326f' }}>
                                    How It Works
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                                    Our platform provides an intuitive interface that allows you to manage your assets seamlessly. Follow these steps to get started:
                                </Typography>
                                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1,  color: '#df326f' }}>Step-by-Step Guide:</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>1. Sign up and create your profile.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>2. Add your assets with detailed information.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>3. Utilize the real-time analytics to monitor performance.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>4. Generate customized reports as needed.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>5. Ensure your data's security with our advanced protocols.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <QuickLinks />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                </Container>
                <Container>
                {/* FAQs Section */}
                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2,  color: '#df326f' }}>
                    Frequently Asked Questions (FAQs)
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>What is Asset Manager Pro?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Asset Manager Pro is a comprehensive platform designed to help you manage your assets efficiently with real-time analytics, secure storage, and easy-to-use tools.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How do I sign up?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Click on the "View Demo" button to see a demo of our platform. To sign up, visit our website and follow the instructions to create your profile.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How secure is Asset Manager Pro?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Asset Manager Pro uses the latest encryption technologies to ensure that your data is secure and protected.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{mb: 2}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Can I track multiple assets?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes, Asset Manager Pro allows you to track multiple assets with detailed information and real-time analytics.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                </Container>                    
            </Box>

            {/* Error Modal */}
            <Modal
                open={!!error}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                    <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
            
            <Copyright />
        </Box>
    );
};

export default AssetManagement;
