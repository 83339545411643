import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActionArea, Button, Breadcrumbs, Link as MuiLink } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Copyright from "../component/Copyright";
import QuickLinks from '../component/QuickLinks';
import marketing from '../assets/img/marketing.png'


const productOfferings = [
    {
        title: 'Gok E-Procurement',
        description: 'A streamlined procurement system for government operations.',
        link: '#'
    },
    {
        title: 'Asset Management',
        description: 'Comprehensive platform for managing assets efficiently.',
        link: '#'
    },
    {
        title: 'Retail Pro',
        description: 'Enhance your retail experience with powerful tools.',
        link: '#'
    },
    {
        title: 'Restro Pro',
        description: 'Streamline restaurant operations with our app.',
        link: '#'
    },
    {
        title: 'Micro Finance Pro',
        description: 'Support for Self Help Groups, Chamas, and community banking.',
        link: '#'
    },
    {
        title: 'Mpesa Online Payment',
        description: 'Seamless integration with Mpesa for online payments.',
        link: '#'
    },
    {
        title: 'Invest In Africa',
        description: 'A platform for startups to connect and grow in Africa.',
        link: '#'
    },
    {
        title: 'Macrobian Therapy',
        description: 'Online therapy sessions with licensed therapists.',
        link: '#'
    },
    {
        title: 'Bootcamp Master Series',
        description: 'Learning and growth opportunities through masterclasses.',
        link: '#'
    }
];

const AffiliateMarketing = () => {
    return (
        <Box>
            <Helmet>
                <title>Affiliate Marketing - Join Our Program and Earn Commissions | Accentrix Cloud</title>
                <meta name="description" content="Learn about our affiliate marketing program, product offerings, and how you can earn commissions by joining our program." />
            </Helmet>

            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>

            {/* Hero Section */}
            <Box
                sx={{
                    height: '300px',
                    backgroundImage: `url(${marketing})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    textAlign: 'center',
                    padding: 3
                }}
            >
            </Box>

            <Grid container spacing={2}>

                <Grid item xs={12} md={8}>
                    <Box sx={{ padding: 3 }}>
                        {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <Typography color="textPrimary">Affiliate Marketing</Typography>
                </Breadcrumbs>
                        <Typography variant="h4" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Affiliate Marketing
                        </Typography>

                        {/* Program Details Section */}
                        <Card sx={{ marginBottom: 3 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                                    Join Our Affiliate Marketing Program
                                </Typography>
                                <Typography variant="body1">
                                    Our affiliate marketing program offers you the opportunity to earn commissions by promoting our products. As an affiliate, you will receive a unique referral link that tracks the sales generated through your promotions. You will earn a commission for each sale made through your link.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 2 }}>
                                    We offer competitive commission rates and provide all the necessary resources to help you succeed. This includes marketing materials, product information, and dedicated support. Whether you are a blogger, influencer, or website owner, our program is designed to help you maximize your earnings.
                                </Typography>
                            </CardContent>
                        </Card>

                        {/* Our Portfolio Section */}
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Our Product Offerings
                        </Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            {productOfferings.map((item, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card>
                                        <CardActionArea href={item.link}>
                                            <CardContent>
                                                <Typography variant="h6" color= '#df326f'>{item.title}</Typography>
                                                <Typography variant="body2">{item.description}</Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        

                        {/* Join Our Program CTA */}
                        <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
                            <Button variant="contained" color="primary" sx={{ backgroundColor: '#df326f', '&:hover': { backgroundColor: '#b02455' }, marginBottom: 3 }}>
                                Join Our Program
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuickLinks />
                </Grid>
            </Grid>
            <Copyright />
        </Box>
    );
};

export default AffiliateMarketing;
