import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [appState, setAppState] = useState({});
  const [started, setStarted] = useState(false);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({
    userId: null,
    name: null,
    email: null,
    phoneNumber: null,
  });

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser({
      userId: null,
      name: null,
      email: null,
      phoneNumber: null,
    });
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://api.accentrixcloud.com/api/insights');
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts', error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, appState, setAppState, started, setStarted, posts, loading, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
