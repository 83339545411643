import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  Badge,
  Chip,
  Fab,
  Button,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const StyledBadge = styled(Badge)`
  width: 100%;
`;

const FollowButton = styled(Button)`
  margin-left: auto;
`;

const UserContext = React.createContext({ email: 'user@example.com' }); // Sample user context

const UserDMS = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get("https://api.accentrixcloud.com/edms");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setDocuments(response.data);
        setFilteredDocuments(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch documents", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredDocuments(
      documents.filter(doc =>
        doc.document_type.toLowerCase().includes(value) ||
        doc.document_path.toLowerCase().includes(value) ||
        new Date(doc.uploaded_at).toLocaleString().toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (documentId) => {
    try {
      await axios.delete(`/edms/${documentId}`);
      fetchDocuments(); // Refresh the documents after deletion
    } catch (error) {
      console.error("Failed to delete document", error);
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Document Type</TableCell>
              <TableCell>Document Path</TableCell>
              <TableCell>Uploaded At</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredDocuments.length > 0 ? (
              filteredDocuments.map((doc) => (
                <TableRow key={doc.id} onClick={() => setSelectedDocument(doc)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{doc.document_type}</TableCell>
                  <TableCell>{doc.document_path}</TableCell>
                  <TableCell>{new Date(doc.uploaded_at).toLocaleString()}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(doc.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No documents to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderDocumentDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>{selectedDocument.document_type}</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Document Path: {selectedDocument.document_path}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Uploaded At: {new Date(selectedDocument.uploaded_at).toLocaleString()}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>My Documents</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedDocument(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Documents
        </Link>
        {selectedDocument && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedDocument.document_type}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedDocument ? (
        renderDocumentDetails()
      ) : (
        renderTable()
      )}
    </Container>
  );
};

export default UserDMS;
