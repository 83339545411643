import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, CircularProgress, TextField, Modal } from '@mui/material';
import { Helmet } from 'react-helmet';
import Navbar from '../component/Navbar';
import Copyright from "../component/Copyright";
import axios from 'axios';

const Careers = () => {
    const [careers, setCareers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formSuccess, setFormSuccess] = useState(null);

    useEffect(() => {
        const fetchCareers = async () => {
            try {
                const response = await axios.get('/careers');
                setCareers(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data. Please try again.');
                setLoading(false);
            }
        };

        fetchCareers();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormLoading(true);
        setFormError(null);

        try {
            const response = await axios.post('/apply', formData);
            setFormSuccess('Your application has been submitted successfully.');
            setFormLoading(false);
        } catch (err) {
            setFormError('There was an error submitting your application. Please try again.');
            setFormLoading(false);
        }
    };

    const handleCloseModal = () => {
        setFormError(null);
        setFormSuccess(null);
    };

    return (
        <Box>
            <Helmet>
                <title>Careers - Vacancies, Bootcamps, and Hackathons</title>
                <meta name="description" content="Explore our career opportunities, including vacancies, bootcamps, and hackathons. Apply for our BootCamp Master Series program to develop your skills in Backend engineering, front end libraries, data science, prompt engineering, and digital marketing." />
            </Helmet>

            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" sx={{ marginBottom: 2 }}>
                    Careers
                </Typography>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {error ? (
                            <Typography color="error" variant="body1">
                                {error}
                            </Typography>
                        ) : (
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                {careers.map((career) => (
                                    <Grid item xs={12} sm={6} md={4} key={career.id}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h6">{career.title}</Typography>
                                                <Typography variant="body2">{career.description}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </>
                )}

                {/* BootCamp Master Series Section */}
                <Card sx={{ marginBottom: 3 }}>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            BootCamp Master Series
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Our online BootCamp Master Series is designed to identify and nurture young talent, developing essential skills in backend engineering, front-end libraries, data science, prompt engineering, and digital marketing. This intensive 3-month program prepares participants for success in the job market.                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        name="phone"
                                        type="tel"
                                        variant="outlined"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button type="submit" variant="contained" color="primary" disabled={formLoading}>
                                        {formLoading ? <CircularProgress size={24} /> : 'Apply'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>

            {/* Error and Success Modal */}
            <Modal
                open={!!formError || !!formSuccess}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    {formError && (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2" color="error">
                                Error
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {formError}
                            </Typography>
                        </>
                    )}
                    {formSuccess && (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2" color="primary">
                                Success
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {formSuccess}
                            </Typography>
                        </>
                    )}
                    <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>

            <Copyright />
        </Box>
    );
};

export default Careers;
