import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Typography, Modal, TextField, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useUser } from '../context/UserContext';

const SignInModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('254');
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const { setUser } = useUser();
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [loading, setLoading] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleLogin = async () => {
    setLoading(true);
    const isAdmin = email.endsWith('@accentrixcloud.com');
    try {
      const response = await axios.post('https://api.accentrixcloud.com/api/auth', { email, password, admin: isAdmin });
      const user = response.data;
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      setSnackbar({ open: true, message: "Login successful!", severity: "success" });

      // Debugging logs
      console.log("Login successful, navigating to profile");
      if (isAdmin) {
        navigate('/admin-profile');
      } else {
        navigate('/user-profile');
      }
      onClose();
    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data?.message || 'Login failed', severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      await axios.put('https://api.accentrixcloud.com/api/auth/forgot-password', { email });
      setSnackbar({ open: true, message: "Password reset email sent.", severity: "success" });
      setForgotPasswordOpen(false);
      onClose();
    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data?.message || 'Forgot password request failed', severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      await axios.post('https://api.accentrixcloud.com/api/users', { name, email, phone });
      setSnackbar({ open: true, message: "Registration successful! Check your email for activation link.", severity: "success" });
      setRegisterOpen(false);
      onClose();
    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data?.message || 'Registration failed', severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Log In</Typography>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleLogin} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Log In"}
          </Button>
          <Button fullWidth onClick={() => setForgotPasswordOpen(true)}>
            Forgot Password?
          </Button>
          <Button fullWidth onClick={() => setRegisterOpen(true)}>
            Register Here
          </Button>
        </Box>
      </Modal>

      {/* Forgot Password Modal */}
      <Modal open={forgotPasswordOpen} onClose={() => setForgotPasswordOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Forgot Password</Typography>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleForgotPassword} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      {/* Register Modal */}
      <Modal open={registerOpen} onClose={() => setRegisterOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Register</Typography>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Phone"
            fullWidth
            margin="normal"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleRegister} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignInModal;
