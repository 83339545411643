import React from 'react';
import LandingPage from './pages/LandingPage';
import DetailsPage from './pages/DetailsPage';
import SetPassword from './component/SetPassword';
import UserProfile from './pages/UserProfile';
import AdminProfile from './pages/AdminProfile';
import CommoditiesTrading from './pages/CommoditiesTrading';
import AssetManagement from './pages/AssetManagement';
import RetailApp from './pages/RetailApp';
import RestaurantApp from './pages/RestaurantApp';
import MicroFinanceApp from './pages/MicroFinanceApp';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Blog from './pages/Blog';
import Article from './component/Article';
import OurPartners from './pages/OurPartners';
import AffiliateMarketing from './pages/AffiliateMarketing';
import Careers from './pages/Careers';
import ViewSuperuserUserAccounts from './pages/ViewSuperuserUserAccounts';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import ViewUserLeads from './pages/ViewUserLeads';
import ViewUserBids from './pages/ViewUserBids';
import ViewUserDMS from './pages/ViewUserDMS';
import ViewUserBilling from './pages/ViewUserBilling';
import ViewUserSubscription from './pages/ViewUserSubscription';
import ViewSuperuserBilling from './pages/ViewSuperuserBilling';
import ViewSuperuserTickets from './pages/ViewSuperuserTickets';
import ViewSuperuserSubscription from './pages/ViewSuperuserSubscription';
import ViewSuperuserBids from './pages/ViewSuperuserBids';
import ViewSuperuserDMS from './pages/ViewSuperuserDMS';
import ViewSuperuserCommodities from './pages/ViewSuperuserCommodities';
import ViewSuperuserBlogposts from './pages/ViewSuperuserBlogposts';
import ViewSuperuserLeads from './pages/ViewSuperuserLeads';

const routes = [
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/:pename',
    element: <DetailsPage />,
  },
  {
    path: '/commodities-trading',
    element: <CommoditiesTrading />,
  },
  {
    path: '/asset-management',
    element: <AssetManagement />,
  },
  {
    path: '/retail-app',
    element: <RetailApp />,
  },
  {
    path: '/restaurant-app',
    element: <RestaurantApp />,
  },
  {
    path: '/micro-finance-app',
    element: <MicroFinanceApp />,
  },
  {
    path: '/about-us',
    element: <AboutUs />,
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
  },
  {
    path: '/blog',
    element: <Blog />,
  },
  {
    path: '/article/:id',
    element: <Article />,
  },
  {
    path: '/partners',
    element: <OurPartners />,
  },
  {
    path: '/affiliate-marketing',
    element: <AffiliateMarketing />,
  },
  {
    path: '/careers',
    element: <Careers />,
  },
  {
    path: '/set-password',
    element: <SetPassword />,
  },
  {
    path: '/accentrix',
    element: <ProtectedRoute element={AdminProfile} />, // Protected route
  },
  {
    path: '/admin-accounts',
    element: <ProtectedRoute element={ViewSuperuserUserAccounts}  />, // Protected route
  },
  {
    path: '/admin-profile',
    element: <ProtectedRoute element={AdminProfile}  />, // Protected route
  },
  {
    path: '/user-profile',
    element: <ProtectedRoute element={UserProfile}   />, // Protected route
  },
  {
    path: '/user-leads',
    element: <ProtectedRoute element={ViewUserLeads}   />, // Protected route
  },
  {
    path: '/user-bids',
    element: <ProtectedRoute element={ViewUserBids}   />, // Protected route
  },
  {
    path: '/user-dms',
    element: <ProtectedRoute element={ViewUserDMS}   />, // Protected route
  },
  {
    path: '/user-billing',
    element: <ProtectedRoute element={ViewUserBilling}   />, // Protected route
  },
  {
    path: '/user-subscription',
    element: <ProtectedRoute element={ViewUserSubscription}   />, // Protected route
  },{
    path: '/billing',
    element: <ProtectedRoute element={ViewSuperuserBilling}   />, // Protected route
  },
  {
    path: '/subscriptions',
    element: <ProtectedRoute element={ViewSuperuserSubscription}   />, // Protected route
  },
  {
    path: '/tickets',
    element: <ProtectedRoute element={ViewSuperuserTickets}   />, // Protected route
  },
  {
    path: '/leads',
    element: <ProtectedRoute element={ViewSuperuserLeads}   />, // Protected route
  },
  {
    path: '/bids',
    element: <ProtectedRoute element={ViewSuperuserBids}   />, // Protected route
  },
  {
    path: '/dms',
    element: <ProtectedRoute element={ViewSuperuserDMS}   />, // Protected route
  },
  {
    path: '/commodities',
    element: <ProtectedRoute element={ViewSuperuserCommodities}   />, // Protected route
  },
  {
    path: '/blog-posts',
    element: <ProtectedRoute element={ViewSuperuserBlogposts}   />, // Protected route
  },
  {
    path: '/settings',
    element: <ProtectedRoute element={ViewSuperuserUserAccounts}  />, // Protected route
  },
];

export default routes;
