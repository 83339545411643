import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  IconButton,
  Snackbar,
  Alert,
  Grid,
  Chip
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon, ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const UserContext = React.createContext({ email: 'user@example.com', phone: '1234567890' }); // Sample user context

const UserLeads = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const user = useContext(UserContext);

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await axios.get("https://api.accentrixcloud.com/leads");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setLeads(response.data);
        setFilteredLeads(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch leads", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredLeads(
      leads.filter(lead =>
        lead.title.toLowerCase().includes(value) ||
        lead.pename.toLowerCase().includes(value) ||
        new Date(lead.closedate).toLocaleString().toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (leadId) => {
    try {
      await axios.delete(`/leads/${leadId}`);
      fetchLeads(); // Refresh the leads after deletion
    } catch (error) {
      console.error("Failed to delete lead", error);
    }
  };

  const handlePostBid = async (lead) => {
    try {
      const bidResponse = await axios.post("https://api.accentrixcloud.com/bids", {
        pename: lead.pename,
        title: lead.title,
        dayremaining: lead.dayremaining,
        closedate: lead.closedate,
        procurementcategory: lead.procurementcategory,
        procurementmethod: lead.procurementmethod,
        submissionmethod: lead.submissionmethod,
        industry: lead.industry,
        user_email: user.email,
      });

      setSnackbar({
        open: true,
        message: 'Bid submitted successfully.',
        severity: 'success',
      });
    } catch (error) {
      console.error("Failed to submit bid", error);
      setSnackbar({
        open: true,
        message: 'Failed to submit bid. Please try again.',
        severity: 'error',
      });
    }
  };

  const getStatusChip = (dayRemaining) => {
    let color;
    if (dayRemaining > 7) {
      color = 'success';
    } else if (dayRemaining > 4) {
      color = 'warning';
    } else {
      color = 'error';
    }
    return <Chip label={`Due in ${dayRemaining} days`} color={color} />;
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Leads</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredLeads.length > 0 ? (
              filteredLeads.map((lead) => (
                <TableRow key={lead.id} onClick={() => setSelectedLead(lead)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{lead.pename}</TableCell>
                  <TableCell>{lead.title}</TableCell>
                  <TableCell>{getStatusChip(lead.dayremaining)}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(lead.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handlePostBid(lead); }}>
                      <AddIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No leads to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderLeadDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>{selectedLead.title}</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Date: {new Date(selectedLead.closedate).toLocaleString()}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Procurement Category: {selectedLead.procurementcategory}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Industry: {selectedLead.industry}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Procurement Method: {selectedLead.procurementmethod}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Submission Method: {selectedLead.submissionmethod}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {getStatusChip(selectedLead.dayremaining)}
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>My Leads</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedLead(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Leads
        </Link>
        {selectedLead && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedLead.title}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedLead ? (
        renderLeadDetails()
      ) : (
        renderTable()
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserLeads;
