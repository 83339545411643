import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  IconButton,
  Button,
  Modal,
  MenuItem,
  Grid
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const SuperuserLeads = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [leadData, setLeadData] = useState({
    name: "",
    email: "",
    phone: "",
    status: "",
  });

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await axios.get("/leads");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setLeads(response.data);
        setFilteredLeads(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch leads", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredLeads(
      leads.filter(lead =>
        lead.name.toLowerCase().includes(value) ||
        lead.email.toLowerCase().includes(value) ||
        lead.phone.toLowerCase().includes(value) ||
        lead.status.toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (leadId) => {
    try {
      await axios.delete(`/leads/${leadId}`);
      fetchLeads(); // Refresh the leads after deletion
    } catch (error) {
      console.error("Failed to delete lead", error);
    }
  };

  const handleModalOpen = (lead = null) => {
    if (lead) {
      setLeadData(lead);
    } else {
      setLeadData({
        name: "",
        email: "",
        phone: "",
        status: "",
      });
    }
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setLeadData({
      name: "",
      email: "",
      phone: "",
      status: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLeadData({
      ...leadData,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      if (leadData.id) {
        await axios.put(`/leads/${leadData.id}`, leadData);
      } else {
        await axios.post("/leads", leadData);
      }
      fetchLeads();
      handleModalClose();
    } catch (error) {
      console.error("Failed to submit lead data", error);
      setLoading(false);
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Lead Records</Typography>
        <Button variant="contained" color="primary" onClick={() => handleModalOpen()}>
          Add New
        </Button>
      </Box>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredLeads.length > 0 ? (
              filteredLeads.map((lead) => (
                <TableRow key={lead.id} onClick={() => setSelectedLead(lead)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>{lead.phone}</TableCell>
                  <TableCell>{lead.status}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleModalOpen(lead); }}>
                      <FontAwesomeIcon icon={faEdit} color="blue" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(lead.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No lead records to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderLeadDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Lead Details</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Name: {selectedLead.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Email: {selectedLead.email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Phone: {selectedLead.phone}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Status: {selectedLead.status}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Leads</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedLead(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Leads
        </Link>
        {selectedLead && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedLead.name}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedLead ? (
        renderLeadDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openModal} onClose={handleModalClose}>
        <Paper sx={{ p: 4, mx: 'auto', mt: '10%', maxWidth: 600 }}>
          <Typography variant="h6" gutterBottom>{leadData.id ? 'Edit Lead' : 'Add New Lead'}</Typography>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleFormSubmit}>
            <TextField
              label="Name"
              name="name"
              value={leadData.name}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Email"
              name="email"
              value={leadData.email}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Phone"
              name="phone"
              value={leadData.phone}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              select
              label="Status"
              name="status"
              value={leadData.status}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              required
            >
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="Contacted">Contacted</MenuItem>
              <MenuItem value="Qualified">Qualified</MenuItem>
              <MenuItem value="Lost">Lost</MenuItem>
              <MenuItem value="Customer">Customer</MenuItem>
            </TextField>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default SuperuserLeads;