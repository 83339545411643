import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Button, Grid, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Modal, Link as MuiLink, Container, Breadcrumbs } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import Navbar from '../component/Navbar';
import QuickLinks from '../component/QuickLinks';
import Copyright from "../component/Copyright";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS
import bgImg1 from '../assets/img/retail1.png'
import bgImg2 from '../assets/img/retail2.png'
import bgImg3 from '../assets/img/retail3.png'


const RetailApp = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Simulate API call for demo purposes
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleCloseModal = () => {
        setError(null);
    };

    return (
        <Box>
            <Helmet>
                <title>Retail Pro - Enhance Your Retail Experience | Accentrix Cloud</title>
                <meta name="description" content="Enhance your retail experience with powerful tools. Our Retail Pro app offers a comprehensive solution for managing your retail business efficiently." />
            </Helmet>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>   
            <Box>

                {/* Carousel Section */}
                <Box sx={{ marginBottom: 3 }}>
                    <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={true} dynamicHeight={true}>
                        <div>
                            <img src={bgImg1} alt="Feature 1" style={{ height: '50vh', objectFit: 'cover' }} />
                        </div>
                        <div>
                            <img src={bgImg2} alt="Feature 2" style={{ height: '50vh', objectFit: 'cover' }} />
                        </div>
                        <div>
                            <img src={bgImg3} alt="Feature 3" style={{ height: '50vh', objectFit: 'cover' }} />
                        </div>
                    </Carousel>
                </Box>
                <Container>
                    {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <Typography color="textPrimary">Retail App</Typography>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={8} lg={8} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color: '#df326f' }}>
                                    Features of Retail Pro
                                </Typography>
                                <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderRadius: 1, marginBottom: 3 }}>
                                            <Typography variant="body1" sx={{ marginBottom: 1 }}>
                                                Retail Pro is designed to transform your retail experience with powerful tools and insights.
                                            </Typography>
                                            <Typography variant="body2">• Seamless shopping experience</Typography>
                                            <Typography variant="body2">• Real-time inventory management</Typography>
                                            <Typography variant="body2">• Customer insights and analytics</Typography>
                                            <Typography variant="body2">• Personalized recommendations</Typography>
                                            <Typography variant="body2">• Easy-to-use interface</Typography>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        component="a"
                                        href="https://mfgs.accentrixcloud.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ backgroundColor: '#df326f', '&:hover': { backgroundColor: '#b02455' }, marginBottom: 3, mt: 3 }}
                                    >
                                        View Demo
                                    </Button>
                                </Box>

                                {/* How It Works Section */}
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color: '#df326f' }}>
                                    How It Works
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                                    Retail Pro offers a comprehensive suite of tools to enhance your retail operations. Here's how to get started:
                                </Typography>
                                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1, color: '#df326f' }}>Step-by-Step Guide:</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>1. Sign up and create your account.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>2. Set up your inventory and add products.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>3. Use the real-time analytics to monitor sales and inventory.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>4. Gain insights into customer behavior and preferences.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>5. Personalize the shopping experience with targeted recommendations.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <QuickLinks />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                </Container>
                <Container>
                {/* FAQs Section */}
                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color: '#df326f' }}>
                    Frequently Asked Questions (FAQs)
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>What is Retail Pro?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Retail Pro is a comprehensive platform designed to enhance your retail operations with real-time inventory management, customer insights, and seamless shopping experiences.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How do I sign up?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Click on the "View Demo" button to see a demo of our platform. To sign up, visit our website and follow the instructions to create your account.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How secure is Retail Pro?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Retail Pro uses advanced security protocols to ensure that your data is protected and secure.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{mb: 2}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Can I manage multiple stores?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes, Retail Pro allows you to manage multiple stores and track inventory, sales, and customer insights across all locations.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                </Container>                     
            </Box>

            {/* Error Modal */}
            <Modal
                open={!!error}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                    <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
            
            <Copyright />
        </Box>
    );
};

export default RetailApp;
