import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, TextField, Button, Grid, Card, CardContent, CardActionArea, CircularProgress, Modal, Breadcrumbs, Link as MuiLink, Hidden } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faWhatsapp, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Navbar from '../component/Navbar';
import Copyright from "../component/Copyright";
import axios from 'axios';

const portfolioItems = [
    {
        title: 'E-Procurement',
        description: 'A streamlined procurement system for bidding tenders in Kenya.',
        link: '/'
    },
    {
        title: 'Asset Management',
        description: 'Comprehensive platform for managing assets efficiently.',
        link: '/asset-management'
    },
    {
        title: 'Retail Pro',
        description: 'Enhance your retail experience with powerful tools.',
        link: '/retail-app'
    },
    {
        title: 'Restro Pro',
        description: 'Streamline restaurant operations with our app.',
        link: '/restaurant-app'
    },
    {
        title: 'Micro Finance Pro',
        description: 'Support for Self Help Groups, Chamas, and community banking.',
        link: '/micro-finance-app'
    },
    {
        title: 'Mpesa Online Payment',
        description: 'Seamless integration with Mpesa for online payments.',
        link: '/'
    },
    {
        title: 'Invest In Africa',
        description: 'A platform for startups to connect and grow in Africa.',
        link: '/'
    },
    {
        title: 'Macrobian Therapy',
        description: 'Online therapy sessions with licensed therapists.',
        link: '/'
    },
    {
        title: 'Bootcamp Master Series',
        description: 'Learning and growth opportunities through masterclasses.',
        link: '/'
    }
];

const ContactUs = () => {
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', inquiry: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.inquiry.length < 10) {
            setError('Inquiry must be at least 10 characters long.');
            return;
        }

        if (!recaptchaToken) {
            setError('Please complete the reCAPTCHA.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.post('https://api.accentrixcloud.com/api/tickets', {
                ...formData,
                recaptchaToken,
            });
            setLoading(false);
            setSuccess('Your inquiry has been submitted successfully.');
        } catch (err) {
            setLoading(false);
            setError('There was an error submitting your inquiry. Please try again.');
        }
    };

    const handleCloseModal = () => {
        setError(null);
        setSuccess(null);
    };

    return (
        <Box>
            <Helmet>
                <title>Contact Us - Accentrix Cloud</title>
                <meta name="description" content="Get in touch with us for any inquiries, support, or feedback. We're here to help you with any questions you have about our products and services." />
            </Helmet>
                <Box sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                }}>
                    <Navbar />
                </Box>
            <Box sx={{ padding: 3 }}>

                {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <Typography color="textPrimary">Contact Us</Typography>
                </Breadcrumbs>

                {/* Contact Us Section */}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h5" sx={{ marginBottom: 3, color: '#df326f' }}>
                        Get in Touch
                    </Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={8} lg={8}>
                        <Box sx={{ marginTop: 4, maxWidth: '95%' }}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name="name"
                                            variant="outlined"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            type="email"
                                            variant="outlined"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Phone Number"
                                            name="phone"
                                            type="tel"
                                            variant="outlined"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <FontAwesomeIcon icon={faPhone} style={{ marginRight: '8px' }} />
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Inquiry"
                                            name="inquiry"
                                            variant="outlined"
                                            value={formData.inquiry}
                                            onChange={handleChange}
                                            multiline
                                            rows={4}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <ReCAPTCHA
                                            sitekey="6LcbOv8pAAAAAA7YmlikurmqqQyKuF8ourF_jE4c" // Replace with your actual site key
                                            onChange={handleRecaptchaChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                            {loading ? <CircularProgress size={24} /> : 'Submit'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                            <Typography variant="h5" sx={{ color: '#df326f', marginBottom: 2 }}>
                                Contact Information
                            </Typography>
                            </Box>
                            <Typography variant="body2"><strong>Company Telephone:</strong> +254 114 047 008</Typography>
                            <Typography variant="body2"><strong>Postal Address:</strong> 12528 - 00400 Tom Mboya St, Nairobi, Kenya</Typography>
                            <Typography variant="body2"><strong>Sales Email:</strong> sales@accentrixcloud.com</Typography>
                            <Typography variant="body2"><strong>Support Email:</strong> support@accentrixcloud.com</Typography>
                        </Box>
                    </Grid>
                </Grid>

                {/* Portfolio Section */}
                <Box sx={{ marginTop: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Our Portfolio
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {portfolioItems.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                    <CardActionArea href={item.link}>
                                        <CardContent>
                                            <Typography variant="h6" sx={{ color: '#df326f' }}>
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body2">{item.description}</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, p: 1 }}>
                                        <Button
                                            component="a"
                                            href={`https://www.facebook.com/sharer/sharer.php?u=${item.link}&quote=Check%20out%20our%20${item.title}%20app%20here:%0A${item.link}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ color: "#3b5998" }}
                                        >
                                            <FontAwesomeIcon icon={faFacebook} size="lg" />
                                        </Button>
                                        <Button
                                            component="a"
                                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${item.link}&title=${item.title}&summary=${item.description}%0A${item.link}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ color: "#0e76a8" }}
                                        >
                                            <FontAwesomeIcon icon={faLinkedin} size="lg" />
                                        </Button>
                                        <Hidden smUp>
                                            <Button
                                                component="a"
                                                href={`https://api.whatsapp.com/send?text=Check%20out%20our%20${item.title}%20app:%0A${item.link}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{ color: "#25D366" }}
                                            >
                                                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                                            </Button>
                                        </Hidden>
                                        <Button
                                            component="a"
                                            href={`https://twitter.com/share?url=${item.link}&text=Check%20out%20our%20${item.title}%20app%0A${item.link}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ color: "#1DA1F2" }}
                                        >
                                            <FontAwesomeIcon icon={faTwitter} size="lg" />
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                {/* Share Your Experience Section */}
                <Box sx={{ marginTop: 4, marginBottom: 4, textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                        Share Your Experience
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Button
                            component="a"
                            href="https://www.facebook.com/sharer/sharer.php?u=accentrixcloud.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#3b5998" }}
                        >
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                        </Button>
                        <Button
                            component="a"
                            href="https://www.linkedin.com/shareArticle?mini=true&url=accentrixcloud.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#0e76a8" }}
                        >
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </Button>
                        <Hidden smUp>
                            <Button
                                component="a"
                                href="https://api.whatsapp.com/send?text=accentrixcloud.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: "#25D366" }}
                            >
                                <FontAwesomeIcon icon={faWhatsapp} size="2x" style={{ color: '#25D366' }} />
                            </Button>
                        </Hidden>
                        <Button
                            component="a"
                            href="https://twitter.com/share?url=accentrixcloud.com&text=Check%20this%20out!"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#1DA1F2" }}
                        >
                            <FontAwesomeIcon icon={faTwitter} size="2x" />
                        </Button>
                    </Box>
                </Box>

                {/* Error and Success Modal */}
                <Modal
                    open={!!error || !!success}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        {error && (
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Error
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {error}
                                </Typography>
                            </>
                        )}
                        {success && (
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Success
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {success}
                                </Typography>
                            </>
                        )}
                        <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained" color="primary">
                            Close
                        </Button>
                    </Box>
                </Modal>

                <Copyright />
            </Box>
        </Box>
    );
};

export default ContactUs;
