import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardContent, Button, Breadcrumbs } from '@mui/material';
import { Helmet } from 'react-helmet';
import Navbar from '../component/Navbar';
import Copyright from "../component/Copyright";
import QuickLinks from '../component/QuickLinks';
import partner from '../assets/img/partner.png'

const OurPartners = () => {
    return (
        <Box>
            <Helmet>
                <title>Our Partners - Seeking Partners, Mentors, and Assets | Accentrix Cloud</title>
                <meta name="description" content="Join us as partners, mentors, or contributors to help us scale. Learn about our needs and how you can contribute." />
            </Helmet>

            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>

            {/* Hero Section */}
            <Box
                sx={{
                    height: '300px',
                    backgroundImage: `url(${partner})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    textAlign: 'center',
                    padding: 3
                }}
            >
                <Typography variant="h2" component="div">
                    Partner with Us
                </Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Box sx={{ padding: 3 }}>
                        <Typography variant="h4" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Our Partners
                        </Typography>

                        {/* Seeking Partners Section */}
                        <Card sx={{ marginBottom: 3 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                                    Seeking Partners
                                </Typography>
                                <Typography variant="body1">
                                    We are actively seeking partners who can help us scale our operations and reach new heights. If you are interested in collaborating with us, please get in touch. We value partnerships that bring mutual benefits and help us achieve our goals.
                                </Typography>
                            </CardContent>
                        </Card>

                        {/* Seeking Mentors Section */}
                        <Card sx={{ marginBottom: 3 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                                    Seeking Mentors
                                </Typography>
                                <Typography variant="body1">
                                    Mentorship is crucial to our growth. We are looking for experienced mentors who can guide us with their expertise and knowledge. Your mentorship can make a significant impact on our journey and help us navigate challenges more effectively.
                                </Typography>
                            </CardContent>
                        </Card>

                        {/* Seeking Assets Section */}
                        <Card sx={{ marginBottom: 3 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                                    Seeking Assets
                                </Typography>
                                <Typography variant="body1">
                                    To scale our operations, we require various assets including technology, financial support, and infrastructure. Your contributions can help us build a robust foundation and enable us to deliver better services to our community.
                                </Typography>
                            </CardContent>
                        </Card>

                        {/* Join Our Program Section */}
                        <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
                            <Button variant="contained" color="secondary" sx={{ marginLeft: 2 }} component={Link} to="/join-program">
                                Join Our Program
                            </Button>
                        </Box>

                        {/* Detailed Information Section */}
                        <Card>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                                    Our Mission
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                    Our mission is to create a thriving ecosystem where innovation and collaboration drive success. We believe in the power of community and strive to create an environment that nurtures growth and development. By joining us, you will be part of a dynamic team that is dedicated to making a positive impact.
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                    As a partner, mentor, or contributor, you will have the opportunity to work with us on exciting projects, share your expertise, and help us overcome challenges. Your support is invaluable, and together, we can achieve great things.
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                    If you are interested in learning more about our partnership opportunities, mentorship programs, or how you can contribute, please get in touch with us. We would love to hear from you and explore how we can work together.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuickLinks />
                </Grid>
            </Grid>
            <Copyright />
        </Box>
    );
};

export default OurPartners;
