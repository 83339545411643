import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  IconButton,
  Grid,
  Modal,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const UserContext = React.createContext({ email: 'user@example.com', phone: '1234567890' }); // Sample user context

const UserBilling = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [billings, setBillings] = useState([]);
  const [filteredBillings, setFilteredBillings] = useState([]);
  const [selectedBilling, setSelectedBilling] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const user = useContext(UserContext);

  useEffect(() => {
    fetchBillings();
  }, []);

  const fetchBillings = async () => {
    try {
      const response = await axios.get("https://api.accentrixcloud.com/billing");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setBillings(response.data);
        setFilteredBillings(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch billings", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBillings(
      billings.filter(billing =>
        billing.user_email.toLowerCase().includes(value) ||
        billing.amount.toString().toLowerCase().includes(value) ||
        billing.status.toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (billingId) => {
    try {
      await axios.delete(`/billing/${billingId}`);
      fetchBillings(); // Refresh the billings after deletion
    } catch (error) {
      console.error("Failed to delete billing", error);
    }
  };

  const handleMakePayment = async () => {
    try {
      const stkResponse = await axios.post('/stk_push', {
        phone: user.phone,
        amount: selectedBilling.amount,
      });

      const paymentResponse = await axios.post('/payments', {
        user_id: selectedBilling.user_id,
        amount: selectedBilling.amount,
        billing_id: selectedBilling.id,
        status: 'Pending',
      });

      setSnackbar({
        open: true,
        message: 'Payment initiated successfully. Please complete the payment on your phone.',
        severity: 'success',
      });
    } catch (error) {
      console.error("Failed to initiate payment", error);
      setSnackbar({
        open: true,
        message: 'Failed to initiate payment. Please try again.',
        severity: 'error',
      });
    } finally {
      setPaymentModalOpen(false);
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>User Email</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredBillings.length > 0 ? (
              filteredBillings.map((billing) => (
                <TableRow key={billing.id} onClick={() => setSelectedBilling(billing)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{billing.user_email}</TableCell>
                  <TableCell>{billing.amount}</TableCell>
                  <TableCell>{billing.status}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(billing.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => { e.stopPropagation(); setSelectedBilling(billing); setPaymentModalOpen(true); }}
                      sx={{ ml: 2 }}
                    >
                      Pay
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No billing records to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderBillingDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Billing Details</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>User Email: {selectedBilling.user_email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Amount: {selectedBilling.amount}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Status: {selectedBilling.status}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>My Billing</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedBilling(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Billing Records
        </Link>
        {selectedBilling && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedBilling.user_email}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedBilling ? (
        renderBillingDetails()
      ) : (
        renderTable()
      )}

      <Modal
        open={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
      >
        <Paper sx={{ p: 4, mx: 'auto', mt: '10%', maxWidth: 400 }}>
          <Typography variant="h6" gutterBottom>Make Payment</Typography>
          <Typography variant="body1" gutterBottom>Amount: {selectedBilling?.amount}</Typography>
          <Typography variant="body1" gutterBottom>Phone: {user.phone}</Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleMakePayment}>
              Confirm Payment
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setPaymentModalOpen(false)} sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Paper>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserBilling;