import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { Helmet } from 'react-helmet';
import Navbar from '../component/Navbar';
import Copyright from "../component/Copyright";
import QuickLinks from '../component/QuickLinks';
const AboutUs = () => {
    return (
        <Box>
            <Helmet>
                <title>About Us - Our History, Mission, Vision, and Unique Selling Points</title>
                <meta name="description" content="Learn about our history, what we do, our mission, vision, unique selling points, and our business canvas model." />
            </Helmet>

            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" sx={{ marginBottom: 2, color: '#df326f' }}>
                    About Us
                </Typography>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}  md={8} lg={8}>
                {/* Brief History Section */}
                <Card sx={{ marginBottom: 3 }}>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Brief History
                        </Typography>
                        <Typography align='justify' variant="body1" >
                        Founded in 2021, our company set out with the goal of delivering tailored cloud solutions to delight our customers. Over the years, we have grown and evolved, continually adapting to the changing market and technological landscape. <br /><br /> Today, we are proud to be a leader in cloud solutions delivery, known for our innovation and unwavering commitment to excellence.
                        </Typography>
                    </CardContent>
                </Card>

                {/* What We Do Section */}
                <Card sx={{ marginBottom: 3 }}>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            What We Do
                        </Typography>
                        <Typography variant="body1" align='justify'>
                        Accentrix Data Systems Ltd empowers businesses of all sizes with cutting-edge Cloud Solutions. Our integrated platform, tailored for startups and dynamic enterprises, delivers unparalleled efficiency and growth opportunities. <br /><br /> With our innovative commodities trading app, advanced digital marketing tools, and robust CMS integration, we provide a seamless experience that streamlines operations, enhances market reach, and boosts performance.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Mission Section */}
                <Card sx={{ marginBottom: 3 }}>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Our Mission
                        </Typography>
                        <Typography variant="body1" align='justify'>
                        Our mission is to deliver exceptional cloud solutions for businesses of all sizes. We are committed to customer focus, integrity, quality, teamwork, and innovation, striving to make a positive impact on the community around us. <br /><br /> Every day, we work towards achieving this mission by understanding and anticipating our clients' needs, providing personalized and reliable services, and continuously improving our technology and processes. We foster a collaborative environment that encourages creativity and innovation, ensuring that our solutions remain at the forefront of the industry. <br /><br /> By maintaining the highest standards of quality and ethical practices, we build lasting relationships with our clients and contribute to the well-being of our community.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Vision Section */}
                <Card sx={{ marginBottom: 3 }}>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Our Vision
                        </Typography>
                        <Typography variant="body1" align='justify'>
                        Our vision is to be the leading cloud solution provider in Kenya. We aim to be the benchmark of excellence in the industry, recognized for our innovative solutions and exceptional customer service. We believe in the power of technology to transform businesses, driving growth and efficiency through cutting-edge cloud services. <br /><br /> We are dedicated to making this vision a reality through continuous investment in research and development, fostering a culture of innovation, and maintaining a steadfast commitment to quality. By building strong partnerships with our clients and focusing on their unique needs, we strive to deliver customized solutions that drive success and set new standards in the industry.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Unique Selling Points Section */}
                <Card sx={{ marginBottom: 3 }}>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Our Unique Selling Points
                        </Typography>
                        <Typography variant="body1" align='justify'>
                        What sets us apart from the competition is our unwavering dedication to personalized service, innovative technology, and comprehensive support.<br /><br /> We offer tailored cloud solutions that meet the unique needs of each client, ensuring seamless integration, robust security, and scalable performance. Our clients receive unmatched reliability, enhanced operational efficiency, and expert guidance every step of the way. <br /><br /> Our commitment to excellence and customer-centric approach makes us the preferred choice for businesses seeking to leverage cloud technology for growth and success in today's competitive market.
                        </Typography>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12} sm={6}  md={4} lg={3}>
                    <QuickLinks />
                </Grid>
                </Grid>
                {/* Business Canvas Model Section */}
                <Card>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2, color: '#df326f' }}>
                            Our Business Canvas Model
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Key Partners</Typography>
                                <Typography variant="body1">Technology partners, financial institutions, marketing partners, industry associations.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Key Activities</Typography>
                                <Typography variant="body1">Platform development, customer support, marketing, continuous improvement, security management.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Key Resources</Typography>
                                <Typography variant="body1">Development team, sales team, customer support, technology infrastructure, intellectual property.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Value Propositions</Typography>
                                <Typography variant="body1">User-friendly platform, secure transactions, real-time analytics, comprehensive management tools, community support.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Customer Relationships</Typography>
                                <Typography variant="body1">Dedicated support, user communities, personalized service, regular updates, feedback loops.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Channels</Typography>
                                <Typography variant="body1">Website, mobile app, social media, email marketing, partner networks.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Customer Segments</Typography>
                                <Typography variant="body1">Individual traders, SMEs, micro-finance groups, agricultural producers, commodity brokers.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Cost Structure</Typography>
                                <Typography variant="body1">Technology costs, salaries, marketing expenses, customer acquisition costs, compliance costs.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" color='#df326f'>Revenue Streams</Typography>
                                <Typography variant="body1">Subscription fees, transaction fees, advertising, data analytics services, consulting.</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Copyright />
        </Box>
    );
};

export default AboutUs;
