import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TRACKING_ID = "G-P7G1JT2BD5"; // Your Tracking ID

// This function initializes gtag.js and configures it
const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Ensure gtag and dataLayer are initialized globally
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() { window.dataLayer.push(arguments); }

    // Load the gtag script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.gtag('js', new Date());
      window.gtag('config', TRACKING_ID);
    };

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    }
  }, []);

  useEffect(() => {
    // Track page views
    window.gtag('config', TRACKING_ID, {
      'page_path': location.pathname + location.search,
    });
  }, [location]);
};

export default useAnalytics;
