import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  Badge,
  Chip,
  Fab,
  Button,
  Tabs,
  Tab,
  MenuItem,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const StyledBadge = styled(Badge)`
  width: 100%;
`;

const FollowButton = styled(Button)`
  margin-left: auto;
`;

const UserContext = React.createContext({ email: 'user@example.com' }); // Sample user context

const UserBids = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [bids, setBids] = useState([]);
  const [filteredBids, setFilteredBids] = useState([]);
  const [selectedBid, setSelectedBid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchBids();
  }, []);

  const fetchBids = async () => {
    try {
      const response = await axios.get("https://api.accentrixcloud.com/bids");
      console.log('API response:', response.data); // Log the entire API response

      if (response.data) {
        setBids(response.data);
        setFilteredBids(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch bids", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBids(
      bids.filter(bid =>
        bid.title.toLowerCase().includes(value) ||
        bid.pename.toLowerCase().includes(value) ||
        new Date(bid.closedate).toLocaleString().toLowerCase().includes(value)
      )
    );
  };

  const handleDelete = async (bidId) => {
    try {
      await axios.delete(`/bids/${bidId}`);
      fetchBids(); // Refresh the bids after deletion
    } catch (error) {
      console.error("Failed to delete bid", error);
    }
  };

  const getStatusChip = (dayRemaining) => {
    let color;
    if (dayRemaining > 7) {
      color = 'success';
    } else if (dayRemaining > 4) {
      color = 'warning';
    } else {
      color = 'error';
    }
    return <Chip label={`Due in ${dayRemaining} days`} color={color} />;
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Bids</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {filteredBids.length > 0 ? (
              filteredBids.map((bid) => (
                <TableRow key={bid.id} onClick={() => setSelectedBid(bid)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{bid.pename}</TableCell>
                  <TableCell>{bid.title}</TableCell>
                  <TableCell>{getStatusChip(bid.dayremaining)}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(bid.id); }}>
                      <FontAwesomeIcon icon={faTrashAlt} color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No bids to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderBidDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>{selectedBid.title}</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Date: {new Date(selectedBid.closedate).toLocaleString()}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Procurement Category: {selectedBid.procurementcategory}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Industry: {selectedBid.industry}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Procurement Method: {selectedBid.procurementmethod}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Submission Method: {selectedBid.submissionmethod}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {getStatusChip(selectedBid.dayremaining)}
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>My Bids</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedBid(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Bids
        </Link>
        {selectedBid && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedBid.title}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedBid ? (
        renderBidDetails()
      ) : (
        renderTable()
      )}
    </Container>
  );
};

export default UserBids;
