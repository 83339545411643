import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Modal, Container, Breadcrumbs, Link as MuiLink } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Copyright from "../component/Copyright";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS

import QuickLinks from '../component/QuickLinks';

import { Helmet } from 'react-helmet';

import bgImg1 from '../assets/img/restro1.png'
import bgImg2 from '../assets/img/restro2.png'
import bgImg3 from '../assets/img/restro3.png'

const RestaurantApp = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Simulate API call for demo purposes
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleCloseModal = () => {
        setError(null);
    };

    return (
        <Box>
            <Helmet>
            <title>Restaurant App - Transform Your Restaurant | Accentrix Cloud</title>
            <meta name="description" content="Find and savor the best dishes at our restaurant. Our app offers a seamless dining experience, with easy reservations, menu browsing, and exclusive offers." />
            </Helmet>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Navbar />
            </Box>   
            <Box>

                {/* Carousel Section */}
                <Box sx={{ marginBottom: 3 }}>
                    <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={true} dynamicHeight={true}>
                        <div>
                            <img src={bgImg1} alt="Feature 1" style={{ height: '50vh', objectFit: 'cover' }} />
                        </div>
                        <div>
                            <img src={bgImg2} alt="Feature 2" style={{ height: '50vh', objectFit: 'cover' }} />
                        </div>
                        <div>
                            <img src={bgImg3} alt="Feature 3" style={{ height: '50vh', objectFit: 'cover' }} />
                        </div>
                    </Carousel>
                </Box>
                <Container sx={{marginBottom: 2}}>
                 {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <MuiLink component={Link} to="/" color="inherit">
                        Home
                    </MuiLink>
                    <Typography color="textPrimary">Restaurant App</Typography>
                </Breadcrumbs>   
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={8} lg={8} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color:'#df326f' }}>
                                    Features of Restaurant Pro
                                </Typography>
                                <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderRadius: 1, marginBottom: 3 }}>
                                            <Typography variant="body1" sx={{ marginBottom: 1 }}>
                                                Restaurant Pro offers a range of features to streamline your restaurant operations and enhance the dining experience.
                                            </Typography>
                                            <Typography variant="body2">• Seamless reservations management</Typography>
                                            <Typography variant="body2">• Real-time menu updates</Typography>
                                            <Typography variant="body2">• Customer feedback and analytics</Typography>
                                            <Typography variant="body2">• Inventory management</Typography>
                                            <Typography variant="body2">• Easy-to-use interface</Typography>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        component="a"
                                        href="http://restaurant-app.accentrixcloud.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ backgroundColor: '#df326f', '&:hover': { backgroundColor: '#b02455' }, marginBottom: 3, mt: 3 }}
                                    >
                                        View Demo
                                    </Button>
                                </Box>

                                {/* How It Works Section */}
                                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color:'#df326f' }}>
                                    How It Works
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                                    Restaurant Pro provides a user-friendly interface to manage your restaurant operations efficiently. Follow these steps to get started:
                                </Typography>
                                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1, color:'#df326f' }}>Step-by-Step Guide:</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>1. Sign up and create your account.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>2. Set up your menu and update it in real-time.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>3. Manage reservations and track customer preferences.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>4. Use customer feedback to improve your services.</Typography>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>5. Monitor inventory and streamline your operations.</Typography>
                                
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        component="a"
                                        href="#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ backgroundColor: '#df326f', '&:hover': { backgroundColor: '#b02455' }, marginBottom: 3, mt: 3 }}
                                    >
                                        Get This App
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Card sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <QuickLinks />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                </Container>
                <Container sx={{mb: 2}}>
                {/* FAQs Section */}
                <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2, color:'#df326f' }}>
                    Frequently Asked Questions (FAQs)
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>What is Restaurant Pro?</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Typography>
                            Restaurant Pro is a comprehensive platform designed to streamline your restaurant operations, manage reservations, update menus in real-time, and gather customer feedback.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How do I sign up?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Click on the "View Demo" button to see a demo of our platform. To sign up, visit our website and follow the instructions to create your account.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How secure is Restaurant Pro?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Restaurant Pro uses advanced security protocols to ensure that your data is protected and secure.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Can I manage multiple locations?</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{mb: 2}}>
                        <Typography>
                            Yes, Restaurant Pro allows you to manage multiple locations, track inventory, reservations, and customer feedback across all your restaurants.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Container>
            </Box>

            {/* Error Modal */}
            <Modal
                open={!!error}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                    <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
            
            <Copyright />
        </Box>
    );
};

export default RestaurantApp;
