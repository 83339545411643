import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  Badge,
  Chip,
  Fab,
  Button,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert
} from "@mui/material";
import { Search as SearchIcon, ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faPlus, faMinus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import styled from 'styled-components';
import { useUser } from '../context/UserContext';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const StyledBadge = styled(Badge)`
  width: 100%;
`;

const FollowButton = styled(Button)`
  margin-left: auto;
`;

const VALIDITY_OPTIONS = ["Yearly", "Bi-annually", "Quarterly", "Monthly", "Weekly", "Daily", "None"];

const UserInbox = () => {
  const { user, logout } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [tenders, setTenders] = useState([]);
  const [tendersByIndustry, setTendersByIndustry] = useState({});
  const [selectedTender, setSelectedTender] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [specModalOpen, setSpecModalOpen] = useState(false);
  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [stepValue, setStepValue] = useState(0);
  const [acknowledged, setAcknowledged] = useState(false);
  const [fileUploads, setFileUploads] = useState({});
  const [validity, setValidity] = useState({});
  const [followed, setFollowed] = useState(false);
  const [logOffModalOpen, setLogOffModalOpen] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [summaryProgress, setSummaryProgress] = useState(0);
  const [summaryData, setSummaryData] = useState("");
  const [proposalOnEmail, setProposalOnEmail] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchIndustries();
  }, []);

  const fetchIndustries = async () => {
    try {
      const response = await axios.get("https://api.accentrixcloud.com/industries");
      if (response.data && typeof response.data === 'object') {
        const tendersByIndustry = {};
        for (const category in response.data) {
          response.data[category].forEach(tender => {
            const industry = tender.industry;
            if (!tendersByIndustry[industry]) {
              tendersByIndustry[industry] = [];
            }
            tendersByIndustry[industry].push(tender);
          });
        }
        const industryNames = Object.keys(tendersByIndustry);
        setIndustries(industryNames);
        setTendersByIndustry(tendersByIndustry);
      }
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch industries", error);
      setSnackbar({ open: true, message: "Failed to load industries", severity: "error" });
      setLoading(false);
    }
  };

  const handleIndustryClick = (industry) => {
    setSelectedIndustry(industry);
    fetchTenders(industry);
  };

  const handleTenderClick = async (tender) => {
    setSelectedTender(tender);
    try {
      const response = await axios.get(`https://api.accentrixcloud.com/tenders/${tender.id}`);
      const tenderDetails = response.data;
      setRecipientEmail(tenderDetails.pe_email || "pe_email@example.com");
    } catch (error) {
      console.error("Failed to fetch tender details", error);
      setSnackbar({ open: true, message: "Failed to load tender details", severity: "error" });
    }
  };

  const handleSpecModalOpen = async () => {
    try {
      setSummaryLoading(true);
      setSummaryProgress(0);

      const documentResponse = await axios.get(`https://api.accentrixcloud.com/document/${selectedTender.id}`);
      const document = documentResponse.data.find(doc => doc.documentable_id === selectedTender.id);
      const fileUrl = `https://tenders.go.ke${document.url}`;
      console.log(fileUrl)
      const formData = new FormData();
      formData.append("file", fileUrl);

      const response = await axios.post('https://api.accentrixcloud.com/summarize_pdf', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setSummaryProgress(percentCompleted);
        }
      });

      setSummaryData(response.data.summary);
      setSnackbar({ open: true, message: "Summary fetched successfully", severity: "success" });
    } catch (error) {
      console.error("Failed to summarize PDF", error);
      setSnackbar({ open: true, message: "Failed to summarize PDF", severity: "error" });
    } finally {
      setSummaryLoading(false);
      setSpecModalOpen(true);
    }
  };

  const handleSpecModalClose = () => {
    setSpecModalOpen(false);
  };

  const handleBidModalOpen = () => {
    setBidModalOpen(true);
  };

  const handleBidModalClose = () => {
    setBidModalOpen(false);
  };

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleStepChange = (step) => {
    setStepValue(step);
  };

  const handleAcknowledgedChange = (event) => {
    setAcknowledged(event.target.checked);
  };

  const handleFileChange = (event, name) => {
    setFileUploads((prev) => ({
      ...prev,
      [name]: event.target.files[0],
    }));
  };

  const handleValidityChange = (event, name) => {
    setValidity((prev) => ({
      ...prev,
      [name]: event.target.value,
    }));
  };

  const fetchTenders = (industry) => {
    setTenders(tendersByIndustry[industry] || []);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (selectedIndustry) {
      setTenders(
        tendersByIndustry[selectedIndustry].filter(tender =>
          tender.title.toLowerCase().includes(value) ||
          new Date(tender.closedate).toLocaleString().toLowerCase().includes(value)
        )
      );
    } else {
      setIndustries(
        industries.filter(industry =>
          industry.toLowerCase().includes(value)
        )
      );
    }
  };

  const handleFollowClick = () => {
    setFollowed(!followed);
  };

  const handleLogOff = () => {
    setLogOffModalOpen(true);
  };

  const handleLogOffConfirm = () => {
    logout(); // Call the logout function from the context
    setLogOffModalOpen(false);
  };

  const handleLogOffCancel = () => {
    setLogOffModalOpen(false);
  };

  const navigateToUserProfile = () => {
    // Use React Router's history.push to navigate without reloading the page
    // history.push('/user-profile');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!acknowledged) {
      setSnackbar({ open: true, message: "Please acknowledge that you have provided correct information.", severity: "warning" });
      return;
    }

    try {
      // Submit leads information
      const leadsResponse = await axios.post("https://api.accentrixcloud.com/leads", {
        email: user.email,
        pename: selectedTender.pename,
        title: selectedTender.title,
        dayremaining: selectedTender.dayremaining,
        closedate: selectedTender.closedate,
        procurementcategory: selectedTender.procurementcategory,
        procurementmethod: selectedTender.procurementmethod,
        submissionmethod: selectedTender.submissionmethod,
        industry: selectedTender.industry,
      });

      // Upload documents to /dms
      const formData = new FormData();
      for (const [name, file] of Object.entries(fileUploads)) {
        formData.append("documents", file, file.name);
        formData.append("validity", validity[name]); // Add validity to the form data
      }
      const dmsResponse = await axios.post("https://api.accentrixcloud.com/edms", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Trigger M-Pesa STK push
      const mpesaResponse = await axios.post('https://api.accentrixcloud.com/mpesa/stk_push', {
        amount: 400,
        phone: user.phone
      });

      if (mpesaResponse.data.success) {
        setSnackbar({ open: true, message: "M-Pesa payment prompt sent successfully.", severity: "success" });
      } else {
        setSnackbar({ open: true, message: "Failed to send M-Pesa payment prompt.", severity: "error" });
      }

      handleConfirmationModalOpen();
    } catch (error) {
      console.error("Failed to submit bid", error);
      setSnackbar({ open: true, message: "Failed to submit bid. Please try again.", severity: "error" });
    }
  };

  const getStatusChip = (closedate) => {
    const today = new Date();
    const closingDate = new Date(closedate);
    const timeDiff = closingDate - today;
    const dayRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
    let color;
    if (dayRemaining > 7) {
      color = 'success';
    } else if (dayRemaining > 4) {
      color = 'warning';
    } else {
      color = 'error';
    }
  
    if (dayRemaining > 0) {
      return <Chip label={`${dayRemaining} days remaining`} color={color} />;
    } else if (dayRemaining === 0) {
      return <Chip label="Closing today" color="warning" />;
    } else {
      return <Chip label="Closed" color="error" />;
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Leads</TableCell>
              <TableCell>Status</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {tenders.length > 0 ? (
              tenders.map((tender) => (
                <TableRow key={tender.id} onClick={() => handleTenderClick(tender)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{tender.pe_name}</TableCell>
                  <TableCell>{tender.title}</TableCell>
                  <TableCell>{getStatusChip(tender.close_at)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No tenders to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderTenderDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>{selectedTender.title}</Typography>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Date: {new Date(selectedTender.close_at).toLocaleString()}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Procurement Category: {selectedTender.procurement_category}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Industry: {selectedTender.industry}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {getStatusChip(selectedTender.close_at)}
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSpecModalOpen}
          disabled={summaryLoading}
          startIcon={
            summaryLoading && (
              <CircularProgress size={20} color="inherit" />
            )
          }
        >
          {summaryLoading ? `${summaryProgress}%` : "View Details"}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleBidModalOpen} sx={{ ml: 2 }}>
          Bid
        </Button>
      </Box>
    </Paper>
  );

  const renderSpecificationModal = () => (
    <Modal open={specModalOpen} onClose={handleSpecModalClose}>
      <Paper sx={{ p: 4, mx: 'auto', mt: '10%', maxWidth: 800 }}>
        <Typography variant="h6" gutterBottom>Specifications</Typography>
        <Typography variant="body1" gutterBottom>Summary: {summaryData}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Document:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSpecModalClose}>
            Close
          </Button>
        </Box>
      </Paper>
    </Modal>
  );

  const renderBidModal = () => (
    <Modal open={bidModalOpen} onClose={handleBidModalClose}>
      <Paper sx={{ p: 4, mx: 'auto', mt: '5%', mb: '5%', maxWidth: 800, maxHeight: '80vh', overflow: 'auto' }}>
        <Typography variant="h6" gutterBottom>Bid</Typography>
        <Stepper activeStep={stepValue} alternativeLabel>
          <Step key="Instructions">
            <StepLabel>Instructions</StepLabel>
          </Step>
          <Step key="Details">
            <StepLabel>Details</StepLabel>
          </Step>
          <Step key="Technical Specifications">
            <StepLabel>Technical Specifications</StepLabel>
          </Step>
          <Step key="Experience">
            <StepLabel>Experience</StepLabel>
          </Step>
          <Step key="Capacity">
            <StepLabel>Capacity</StepLabel>
          </Step>
          <Step key="Location">
            <StepLabel>Location</StepLabel>
          </Step>
          <Step key="Financial Status">
            <StepLabel>Financial Status</StepLabel>
          </Step>
          <Step key="Declarations">
            <StepLabel>Declarations</StepLabel>
          </Step>
          <Step key="Submit Bid">
            <StepLabel>Submit Bid</StepLabel>
          </Step>
        </Stepper>
        <Box sx={{ mt: 2 }}>
          {stepValue === 0 && renderInstructions()}
          {stepValue === 1 && renderBidDetailsForm()}
          {stepValue === 2 && renderTechnicalSpecificationsForm()}
          {stepValue === 3 && renderExperienceForm()}
          {stepValue === 4 && renderCapacityForm()}
          {stepValue === 5 && renderLocationForm()}
          {stepValue === 6 && renderFinancialStatusForm()}
          {stepValue === 7 && renderDeclarationsForm()}
          {stepValue === 8 && renderSubmitBidForm()}
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            onClick={() => handleStepChange(Math.max(stepValue - 1, 0))}
            style={{ backgroundColor: "#4caf50", color: "#ffffff", marginRight: '10px' }}
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => handleStepChange(Math.min(stepValue + 1, 8))}
            style={{ backgroundColor: "#4caf50", color: "#ffffff", marginLeft: '10px' }}
            endIcon={<FontAwesomeIcon icon={faArrowRight} />}
          >
            Next
          </Button>
        </Box>
      </Paper>
    </Modal>
  );

  const renderInstructions = () => (
    <Box>
      <Typography variant="body1" gutterBottom>
        Please ensure that you provide the following documents:
      </Typography>
      <ul>
        <li>Company’s Certificate of Incorporation/Registration</li>
        <li>Valid Tax Compliance Certificate</li>
        <li>Business Permit</li>
        <li>Tender Security Details</li>
        <li>Confidential Business Questionnaire</li>
        <li>Declarations (SD1, SD2, etc.)</li>
        <li>Technical Specifications</li>
        <li>Past Experience Documents</li>
        <li>Capacity Documents</li>
        <li>Contact Details and Physical Location</li>
        <li>Financial Status</li>
      </ul>
      <Typography variant="body1" gutterBottom>
        Ensure that all information provided is accurate and concise. Valid documentation, licenses, and certifications must be provided. Contact the respective organizations after bidding in the Leads menu dropdown.
      </Typography>
    </Box>
  );

  const renderBidDetailsForm = () => (
    <Box component="form" sx={{ mt: 2 }}>
      <Typography variant="h6">Details</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography>Certificate of Incorporation/Registration</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "incorporationCertificate")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["incorporationCertificate"] || "None"}
            onChange={(event) => handleValidityChange(event, "incorporationCertificate")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Valid Tax Compliance Certificate</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "taxComplianceCertificate")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["taxComplianceCertificate"] || "None"}
            onChange={(event) => handleValidityChange(event, "taxComplianceCertificate")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Business Permit</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "businessPermit")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["businessPermit"] || "None"}
            onChange={(event) => handleValidityChange(event, "businessPermit")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Copy of CR12</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "cr12")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["cr12"] || "None"}
            onChange={(event) => handleValidityChange(event, "cr12")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Copy of AGPO Certificate</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "agpoCertificate")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["agpoCertificate"] || "None"}
            onChange={(event) => handleValidityChange(event, "agpoCertificate")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const renderTechnicalSpecificationsForm = () => (
    <Box component="form" sx={{ mt: 2 }}>
      <Typography variant="h6">Technical Specifications</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography>Technical Specifications Documents</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "technicalSpecifications")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["technicalSpecifications"] || "None"}
            onChange={(event) => handleValidityChange(event, "technicalSpecifications")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Equipment details and compliance with specific standards</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "equipmentDetails")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["equipmentDetails"] || "None"}
            onChange={(event) => handleValidityChange(event, "equipmentDetails")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Manufacturer’s Authorization</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "manufacturersAuthorization")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["manufacturersAuthorization"] || "None"}
            onChange={(event) => handleValidityChange(event, "manufacturersAuthorization")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const renderExperienceForm = () => (
    <Box component="form" sx={{ mt: 2 }}>
      <Typography variant="h6">Experience</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography>Experience Documents (Contracts, LPOs, Recommendation Letters, etc.)</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "experienceDocuments")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["experienceDocuments"] || "None"}
            onChange={(event) => handleValidityChange(event, "experienceDocuments")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const renderCapacityForm = () => (
    <Box component="form" sx={{ mt: 2 }}>
      <Typography variant="h6">Capacity</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography>Capacity Documents (Resumes, Certifications, etc.)</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "capacityDocuments")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["capacityDocuments"] || "None"}
            onChange={(event) => handleValidityChange(event, "capacityDocuments")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const renderLocationForm = () => (
    <Box component="form" sx={{ mt: 2 }}>
      <Typography variant="h6">Location</Typography>
      <Box sx={{ mt: 2 }}>
        <TextField label="Telephone" fullWidth variant="outlined" margin="normal" required />
        <TextField label="Email" fullWidth variant="outlined" margin="normal" required />
        <TextField label="Contact Person" fullWidth variant="outlined" margin="normal" required />
        <TextField label="Physical Location" fullWidth variant="outlined" margin="normal" required />
        <TextField label="Website" fullWidth variant="outlined" margin="normal" />
      </Box>
    </Box>
  );

  const renderFinancialStatusForm = () => (
    <Box component="form" sx={{ mt: 2 }}>
      <Typography variant="h6">Financial Status</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography>Tender Security Details</Typography>
        <TextField type="number" label="Amount (Kshs.)" fullWidth variant="outlined" margin="normal" />
        <TextField select label="Type" fullWidth variant="outlined" margin="normal" required>
          <MenuItem value="Cash">Cash</MenuItem>
          <MenuItem value="Banker’s Cheque">Banker’s Cheque</MenuItem>
          <MenuItem value="Bid Bond">Bid Bond</MenuItem>
        </TextField>
        <TextField label="Issuing Bank/Insurance" fullWidth variant="outlined" margin="normal" required />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Financial Statements, Audit Reports or Bank Statements</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "financialStatements")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["financialStatements"] || "None"}
            onChange={(event) => handleValidityChange(event, "financialStatements")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const renderDeclarationsForm = () => (
    <Box component="form" sx={{ mt: 2 }}>
      <Typography variant="h6">Declarations</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography>Dully filled and stamped self-declaration form that the user will not engage in any corrupt or fraudulent practice</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "corruptionDeclaration")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["corruptionDeclaration"] || "None"}
            onChange={(event) => handleValidityChange(event, "corruptionDeclaration")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Dully filled and stamped self-declaration form that the user is not debarred</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "debarmentDeclaration")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["debarmentDeclaration"] || "None"}
            onChange={(event) => handleValidityChange(event, "debarmentDeclaration")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Confidential Business Questionnaire</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "confidentialBusinessQuestionnaire")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["confidentialBusinessQuestionnaire"] || "None"}
            onChange={(event) => handleValidityChange(event, "confidentialBusinessQuestionnaire")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Other Declarations</Typography>
        <TextField type="file" fullWidth variant="outlined" margin="normal" required onChange={(event) => handleFileChange(event, "otherDeclarations")} />
        <FormControl fullWidth sx={{ mt: 2 }} required>
          <InputLabel>Validity</InputLabel>
          <Select
            value={validity["otherDeclarations"] || "None"}
            onChange={(event) => handleValidityChange(event, "otherDeclarations")}
          >
            {VALIDITY_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const renderSubmitBidForm = () => (
    <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
      <TextField label="Your Email" fullWidth variant="outlined" margin="normal" required defaultValue={user.email} />
      <TextField label="Recipient's Email" fullWidth variant="outlined" margin="normal" required defaultValue={recipientEmail} />
      <FormControlLabel
        control={<Checkbox checked={acknowledged} onChange={handleAcknowledgedChange} />}
        label="I acknowledge that I have provided correct information."
      />
      <FormControlLabel
        control={<Checkbox checked={proposalOnEmail} onChange={(event) => setProposalOnEmail(event.target.checked)} />}
        label="Get Proposal on Email"
      />
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </Box>
  );

  const renderConfirmationModal = () => (
    <Modal open={confirmationModalOpen} onClose={handleConfirmationModalClose}>
      <Paper sx={{ p: 4, mx: 'auto', mt: '20%', maxWidth: 400 }}>
        <Typography variant="h6" gutterBottom>Confirm M-Pesa Payment</Typography>
        <Typography variant="body1" gutterBottom>Send M-Pesa payment request to {user.phone}?</Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={handleConfirmationModalClose}>
            Confirm
          </Button>
          <Button variant="contained" color="secondary" onClick={handleConfirmationModalClose}>
            Cancel
          </Button>
        </Box>
      </Paper>
    </Modal>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>My Inbox</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedIndustry(null);
            setSelectedTender(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Industries
        </Link>
        {selectedIndustry && (
          <Link
            underline="hover"
            color="inherit"
            onClick={() => setSelectedTender(null)}
            sx={{ cursor: 'pointer' }}
          >
            {selectedIndustry}
          </Link>
        )}
        {selectedTender && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedTender.title}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedTender ? (
        renderTenderDetails()
      ) : selectedIndustry ? (
        renderTable()
      ) : (
        <Grid container spacing={2}>
          {industries.map((industry, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledBadge badgeContent={tendersByIndustry[industry].length} color="primary">
                <Card onClick={() => handleIndustryClick(industry)} sx={{ cursor: 'pointer', width: '100%' }}>
                  <CardContent>
                    <Typography variant="h6">{industry}</Typography>
                  </CardContent>
                  <CardActions>
                  <FollowButton
                      size="small"
                      color="primary"
                      onClick={handleFollowClick}
                      startIcon={<FontAwesomeIcon icon={followed ? faMinus : faPlus} />}
                    >
                      {followed ? "Unfollow" : "Follow"}
                    </FollowButton>
                    </CardActions>
                  </Card>
                </StyledBadge>
              </Grid>
            ))}
          </Grid>
        )}
  
        {selectedIndustry && (
          <Fab
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
            <ArrowUpwardIcon />
          </Fab>
        )}
  
        {renderSpecificationModal()}
        {renderBidModal()}
        {renderConfirmationModal()}
  
        <SpeedDial
          ariaLabel="SpeedDial example"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            icon={<ArrowUpwardIcon />}
            tooltipTitle="Dashboard"
            onClick={navigateToUserProfile}
          />
          <SpeedDialAction
            icon={<FontAwesomeIcon icon={faSignOutAlt} />}
            tooltipTitle="Log Off"
            onClick={handleLogOff}
          />
        </SpeedDial>
  
        <Modal open={logOffModalOpen} onClose={handleLogOffCancel}>
          <Paper sx={{ p: 4, mx: 'auto', mt: '20%', maxWidth: 400 }}>
            <Typography variant="h6" gutterBottom>Log Off</Typography>
            <Typography variant="body1" gutterBottom>Are you sure you want to log off?</Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" onClick={handleLogOffConfirm}>
                Yes
              </Button>
              <Button variant="contained" color="secondary" onClick={handleLogOffCancel}>
                No
              </Button>
            </Box>
          </Paper>
        </Modal>
  
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    );
  };
  
  export default UserInbox;
  