import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Box,
  Toolbar,
  CssBaseline,
  Typography,
  IconButton,
  Container,
  Grid,
  Divider,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Badge,
  Button,
  useTheme
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../component/Navbar";
import AdminMenu from "../component/AdminMenu";
import SuperuserBids from "../component/SuperuserBids";

const drawerWidth = 240;
const closedDrawerWidth = 0;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: closedDrawerWidth,
    width: `calc(100% - ${closedDrawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
    },
  }));

const defaultTheme = createTheme();

const ViewSuperuserBids = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleBidsViewClick = () => {
    navigate('/bids');
  };

  const handleBillingViewClick = () => {
    navigate('/billing');
  };

  const handleDashboardViewClick = () => {
    navigate('/admin-profile');
  };

  const handleLeadsViewClick = () => {
    navigate('/leads');
  };

  const handleAccountsViewClick = () => {
    navigate('/admin-accounts');
  };

  const handleDMSViewClick = () => {
    navigate('/dms');
  };

  const handleSubscriptionsViewClick = () => {
    navigate('/subscriptions');
  };

  const handleProfileViewClick = () => {
    navigate('/settings');
  };

  const handleBlogpostsViewClick = () => {
    navigate('/blog-posts');
  };

  const handleCommoditiesViewClick = () => {
    navigate('/commodities');
  };

  const handleTicketsViewClick = () => {
    navigate('/tickets');
  };

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              accentrixcloud.com
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <FontAwesomeIcon icon={faBell} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
        variant="temporary"
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>
          </Toolbar>
          <Divider />
          <AdminMenu
            handleBidsViewClick={handleBidsViewClick}
            handleLeadsViewClick={handleLeadsViewClick}
            handleBlogPostsViewClick={handleBlogpostsViewClick}
            handleDMSViewClick={handleDMSViewClick}
            handleTicketsViewClick={handleTicketsViewClick}
            handleCommoditiesViewClick={handleCommoditiesViewClick}
            handleProfileViewClick={handleProfileViewClick}
            handleSubscriptionsViewClick={handleSubscriptionsViewClick}
            handleAccountsViewClick={handleAccountsViewClick}
            handleBillingViewClick={handleBillingViewClick}
            handleDashboardViewClick={handleDashboardViewClick}
          />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <SuperuserBids />
                </Paper>
              </Grid>
            </Grid>
            <Typography style={{ color: "#000", paddingTop: 10 }}>
              All Rights Reserved © {new Date().getFullYear()}
            </Typography>
          </Container>
        </Box>
      </Box>
  );
};

export default ViewSuperuserBids;