import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Link,
  Badge,
  Chip,
  Fab,
} from "@mui/material";
import { Search as SearchIcon, ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";
import axios from "axios";
import styled from 'styled-components';

const BoldTableRow = styled(TableRow)`
  font-weight: bold;
`;

const StyledBadge = styled(Badge)`
  width: 100%;
`;

const AdminInbox = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [tenders, setTenders] = useState([]);
  const [tendersByIndustry, setTendersByIndustry] = useState({});
  const [selectedTender, setSelectedTender] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchIndustries();
  }, []);

  const fetchIndustries = async () => {
    try {
      const response = await axios.get("https://api.accentrixcloud.com/industries");

      if (response.data && typeof response.data === 'object') {
        const tendersByIndustry = {};
        for (const category in response.data) {
          response.data[category].forEach(tender => {
            const industry = tender.industry;
            if (!tendersByIndustry[industry]) {
              tendersByIndustry[industry] = [];
            }
            tendersByIndustry[industry].push(tender);
          });
        }
        const industryNames = Object.keys(tendersByIndustry);
        setIndustries(industryNames);
        setTendersByIndustry(tendersByIndustry);
      } else {
        console.error('Unexpected API response format:', response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch industries", error);
      setLoading(false);
    }
  };

  const fetchTenders = (industry) => {
    setTenders(tendersByIndustry[industry] || []);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (selectedIndustry) {
      setTenders(
        tendersByIndustry[selectedIndustry].filter(tender =>
          tender.title.toLowerCase().includes(value) ||
          new Date(tender.closedate).toLocaleString().toLowerCase().includes(value)
        )
      );
    } else {
      setIndustries(
        industries.filter(industry =>
          industry.toLowerCase().includes(value)
        )
      );
    }
  };

  const handleIndustryClick = (industry) => {
    setSelectedIndustry(industry);
    fetchTenders(industry);
  };

  const handleTenderClick = (tender) => {
    setSelectedTender(tender);
  };

  const getStatusChip = (close_at) => {
    const today = new Date();
    const closingDate = new Date(close_at);
    const timeDiff = closingDate - today;
    const dayremaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
    if (dayremaining > 0) {
      return <Chip label={`${dayremaining} days remaining`} color="primary" />;
    } else if (dayremaining === 0) {
      return <Chip label="Closing today" color="warning" />;
    } else {
      return <Chip label="Closed" color="error" />;
    }
  };

  const renderTable = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <BoldTableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Leads</TableCell>
              <TableCell>Status</TableCell>
            </BoldTableRow>
          </TableHead>
          <TableBody>
            {tenders.length > 0 ? (
              tenders.map((tender) => (
                <TableRow key={tender.id} onClick={() => handleTenderClick(tender)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{tender.pe_name}</TableCell>
                  <TableCell>{tender.title}</TableCell>
                  <TableCell>{getStatusChip(tender.close_at)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No tenders to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderTenderDetails = () => (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Typography color="#df326f  " variant="body1" gutterBottom>{selectedTender.title}</Typography>
      <Grid container spacing={2} direction={isMobile ? "row" : "column"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Date: {new Date(selectedTender.close_at).toLocaleString()}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>Procurement Category: {selectedTender.procurement_category}</Typography>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          {getStatusChip(selectedTender.close_at)}
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>My Inbox</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
      </Toolbar>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            setSelectedIndustry(null);
            setSelectedTender(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Industries
        </Link>
        {selectedIndustry && (
          <Link
            underline="hover"
            color="inherit"
            onClick={() => setSelectedTender(null)}
            sx={{ cursor: 'pointer' }}
          >
            {selectedIndustry}
          </Link>
        )}
        {selectedTender && (
          <Typography
            color="text.primary"
            sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              maxWidth: isMobile ? '150px' : '300px' 
            }}
          >
            {selectedTender.title}
          </Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedTender ? (
        renderTenderDetails()
      ) : selectedIndustry ? (
        renderTable()
      ) : (
        <Grid container spacing={2}>
          {industries.map((industry, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledBadge badgeContent={tendersByIndustry[industry].length} color="primary">
                <Card onClick={() => handleIndustryClick(industry)} sx={{ cursor: 'pointer', width: '100%' }}>
                  <CardContent>
                    <Typography variant="body2">{industry}</Typography>
                  </CardContent>
                </Card>
              </StyledBadge>
            </Grid>
          ))}
        </Grid>
      )}

      {selectedIndustry && (
        <Fab
          color="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </Container>
  );
};

export default AdminInbox;
